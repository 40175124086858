const options = { 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric' 
};

function BlogCard({ item, key }) {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', options);
  }

  return (
    <div className="col-span-1 p-4" key={key}>
      <div className="bg-white rounded-xl shadow-gray-200 p-4 hover:shadow-md border border-transparent hover:border-gray-50 transition-all">
        <a href={`blog/${item.slug}`}>
          <img
            src={item.coverImage}
            alt={'Blog Cover Image'}
            className="w-full aspect-[3/2] rounded-md"
          />
        </a>
        <div className="mt-4">
          <a href={`blog/${item.slug}`} className="text-primary text-lg font-bold">
            {item.title}
          </a>
          <p className="text-gray-500 text-sm mt-2">
            {item.intro}
          </p>
          <p className="text-gray-500 text-sm mt-2">
            {item.author} • {formatDate(item.createdAt)} • {item.minRead} min read
          </p>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
