import React from "react";
import { useLocation } from "react-router-dom";
import { PiConfettiBold } from "react-icons/pi";
import { IoDocuments } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdFeaturedPlayList } from "react-icons/md";

const links = [
  {
    title: "Generate Article",
    link: "",
    icon: <PiConfettiBold className="w-5 h-5 md:w-6 md:h-6" />,
  },
  {
    title: "Regenerate Article",
    link: "regenerate",
    icon: <MdFeaturedPlayList className="w-5 h-5 md:w-6 md:h-6" />,
  },
  {
    title: "History",
    link: "history",
    icon: <IoDocuments className="w-5 h-5 md:w-6 md:h-6" />,
  },
];

function LeftSidebar({ credit }) {
  const location = useLocation();
  const pathname = location.pathname.split("/")[2] || "";

  return (
    <div className="hidden md:flex border-r min-w-72 w-72 max-w-72 p-4 h-[calc(100vh-56px)] sticky left-0 bottom-0 top-[56px] flex-col justify-between bg-white z-10 space-y-4">
      <div className="space-y-4">
        <div className="hidden md:flex flex-col gap-3">
          <p className="font-semibold text-sm">Started Here</p>
          {links.map((link, index) => (
            <Link
              key={index}
              style={{
                backgroundColor: link.link === pathname ? "#6035e0" : "",
                color: link.link === pathname ? "white" : "black",
              }}
              className="p-2 ps-4 cursor-pointer hover:scale-105 transition-all duration-200 hover:shadow-sm border hover:bg-slate-50 hover:border-slate-400 pe-4 rounded-md flex justify-start gap-3 flex-row items-center"
              to={`/dashboard${link.link ? "/" + link.link : ""}`}
            >
              {link.icon}
              <p className="font-semibold">{link.title}</p>
            </Link>
          ))}
        </div>
      </div>
      <div className="hidden md:flex flex-col gap-2 bg-purple-100 bg-opacity-80 rounded-md p-3">
        {/* <img src={adsImg} alt="No img" className="w-3/5 mx-auto" /> */}
        <p className="cursive text-primary text-lg text-center">Coming Soon</p>
        <p className="font-semibold text-center">
          TypeFast AI - New Chrome Extension
        </p>
        {/* <button
          className="bg-white border font-semibold border-white hover:border-slate-400 transition-all duration-200 hover:shadow-md p-1 ps-4 pe-4 rounded-md h-fit text-center"
        >
          Upgrade to Premium
        </button> */}
        <div className="bg-white border font-semibold border-white hover:border-slate-400 transition-all duration-200 hover:shadow-md p-2 rounded-md h-fit text-center flex gap-4 justify-center">
          <img src="/chrom.png" width={24} height={24} alt="Chrome Icon" className="h-6" />
          <img src="/firefox.png" width={24} height={24} alt="Firefox Icon" className="h-6" />
          <img src="/brave.png" height={24} alt="Brave Icon" className="h-6" />
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
