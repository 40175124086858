import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";

function Refund() {
  return (
    <div className="p-6 sm:p-16 pt-8 pb-8 flex flex-col gap-6 sm:gap-10  bg-[#6035e0]">
      <Link to={"/"} className="text-white flex flex-row items-center gap-2 w-fit">
        <MdKeyboardBackspace size={24} />
        <p>Back</p>
      </Link>
      <p className="font-bold text-3xl sm:text-5xl text-white">
        Refund and Cancellation Policy
      </p>
      <div className="text-white flex flex-col gap-3 pb-5">
        <p>
          Thank you for choosing TypeFast AI. Before you proceed with any purchase or subscription, please carefully review our Refund and Cancellation Policy.
        </p>
        <p className="font-bold text-xl">1. Subscription Plans</p>
        <p>
          TypeFast AI may offer subscription plans with various features and durations. These plans may include free trials, monthly, or yearly subscriptions.
        </p>
        <p className="font-bold text-xl">2. Free Trials</p>
        <p>
          Free trials are provided to give users the opportunity to explore the features of TypeFast AI before committing to a paid subscription. During the free trial period, you may cancel at any time without incurring charges.
        </p>
        <p className="font-bold text-xl">3. Paid Subscriptions</p>
        <p>
          Upon the expiration of the free trial or at the end of each billing cycle for paid subscriptions, your payment method will be automatically charged unless you cancel your subscription before the renewal date.
        </p>
        <p className="font-bold text-xl">4. Cancellation</p>
        <p>
          You can cancel your subscription at any time by accessing your account settings or contacting our customer support.
        </p>
        <p>
          For monthly subscriptions, cancellation will be effective at the end of the current billing cycle. For yearly subscriptions, cancellation will be effective at the end of the current subscription period.
        </p>
        <p className="font-bold text-xl">5. Refunds</p>
        <p>
          TypeFast AI does not provide refunds for subscription fees already paid, including any unused portion of the subscription period.
        </p>
        <p className="font-bold text-xl">6. Modification or Termination</p>
        <p>
          TypeFast AI reserves the right to modify, suspend, or terminate the services at any time, with or without notice.
        </p>
        <p className="font-bold text-xl">7. Contact Us</p>
        <p>
          If you have any questions or concerns about our Refund and Cancellation Policy, please contact us at diyanshu0360@gmail.com.
        </p>
        <p>
          By using TypeFast AI and engaging in any purchase or subscription, you acknowledge that you have read, understood, and agreed to the terms outlined in this Refund and Cancellation Policy.
        </p>
        <p>Last Updated: 11 December, 2023</p>
      </div>
    </div>
  );
}

export default Refund;
