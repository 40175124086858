import React, { useState } from "react";
import { FaAngleRight } from "react-icons/fa6";

const FAQs = [
  {
    question: "What is TypeFast AI?",
    answer: "TypeFast AI is your go-to tool for easy and impactful writing. Whether you're crafting general articles or promoting products for platforms like Reddit, LinkedIn, Twitter, Medium, and Blogs, TypeFast AI simplifies the process. The best part? It's 100% free to use – no credit card or payment required – and offers unlimited article generation. Write without limits and make a big impact with TypeFast AI!"
  },
  {
    question: "Is TypeFast AI free to use?",
    answer: "Yes, TypeFast AI is entirely free. No hidden fees or credit card requirements – just unlimited access to article generation."
  },
  {
    question: "How does TypeFast AI generate articles?",
    answer: "TypeFast AI generates articles based on your inputs, allowing customization with optional filters like tone, keywords, and range."
  },
  {
    question: "What platforms does TypeFast AI support?",
    answer: "TypeFast AI supports multiple platforms, including Reddit, Medium, Twitter, LinkedIn, and Blogs, giving you flexibility in content creation."
  },
  {
    question: "Can I save and manage my article history on TypeFast AI?",
    answer: "Yes, you can. TypeFast AI allows you to save and manage your article history for easy retrieval and organization."
  },
  {
    question: "Is customer support available?",
    answer: "Yes, we offer 24/7 customer support to address any questions or feedback you may have."
  },
  {
    question: "Are there any limitations on the number of articles I can generate?",
    answer: "No, TypeFast AI allows unlimited article generation, giving you the freedom to write as much as you want."
  },
  {
    question: "How do I stay updated on TypeFast AI's new features and releases?",
    answer: "Stay informed by signing up on dashboard and following our founders on social media for the latest updates."
  },
]

const Faqcard = (props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => setVisible(!visible);

  const containerClasses = `transition-all border bg-slate-50 ${visible
    ? `hover:border-slate-700`
    : "hover:border-slate-700 text-slate-600"
    } w-[100%] lg:w-[80%] rounded-md flex items-center justify-start cursor-pointer`;

  return (
    <div className={containerClasses} onClick={toggleVisibility}>
      <div className="p-1 text-start w-full">
        <div className="flex justify-between items-center gap-1 p-3 md:p-5">
          <p className="font-semibold ">{props.question}</p>
          <FaAngleRight className={`${visible ? 'rotate-90' : ''} transition-all`} />
        </div>
        {visible &&
          <div className="flex items-center p-3 md:p-5 pt-0">
            <p className="text-start text-slate-600">{props.answer}</p>
          </div>
        }
      </div>
    </div>
  );
};

function Faqs() {
  return (
    <div id="FAQs" className="p-5 md:py-10 text-center flex flex-col justify-center items-center gap-5 sm:gap-10">
      <div className="space-y-1 sm:space-y-2">
        <p className="cursive text-primary text-lg text-center">Most Commonly Doubts are solved</p>
        <p className="font-bold text-2xl md:text-5xl w-full text-center">
          Frequently Asked Questions
        </p>
      </div>
      <div className="flex flex-col items-center justify-center w-full md:w-3/4 gap-4">
        {FAQs.map((item, index) => (
          <>
            <Faqcard
              key={index}
              question={item.question}
              answer={item.answer}
            />
          </>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
