import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Navbar from "../components/landingpage/Navbar";
import Footer from "../components/landingpage/Footer";
import BlogCard from "../components/BlogCard";
import SubscribeAds from "../components/landingpage/SubscribeAds";
const BACKEND_URL = process.env.REACT_APP_BACKEND_HOSTED_URL;

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BACKEND_URL}/api/blog`);

        if (response.status === 200) {
          setBlogs(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchBlog();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="w-full">
        <div className="max-w-7xl w-full m-auto p-8 sm:py-16 md:pb-8 md:pt-16 flex flex-col gap-3 justify-center text-center items-center">
          <div className="space-y-1 sm:space-y-2">
            <p className="cursive text-primary text-lg text-center">Latest</p>
            <p className="font-bold text-3xl md:text-5xl w-full text-center">
            Articles & Blogs
            </p>
          </div>
          <p className="w-full md:w-[60%] text-gray-500 text-lg">
            Your Go-To Resource for Learning and Inspiration
          </p>
        </div>
        <div className="max-w-7xl w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 m-auto pb-16">
          {blogs.map((item, index) => (
            <BlogCard item={item} key={index} />
          ))}
        </div>
      </div>
      <SubscribeAds />
      <Footer />
    </div>
  );
}

export default Blog;
