function Testimonial() {
    return (
        <div className="w-full" id="testimonials">
            <div id="features" className="max-w-7xl m-auto px-5 py-10 flex flex-col gap-5 sm:gap-10  justify-center items-center">
                <div className="space-y-1 sm:space-y-2">
                    <p className="cursive text-primary text-lg text-center">Our Wall of Love</p>
                    <p className="font-bold text-3xl md:text-5xl w-full text-center">
                        Testimonials
                    </p>
                </div>
                <div className="w-full">
                    <div className="senja-embed w-full" data-id="fdd192fa-4256-40fb-b056-a29a8f5eba03" data-lazyload="true"></div>
                </div>
            </div>
        </div >
    )
}

export default Testimonial;