import React, { useState, useEffect } from "react";
import { PiConfettiBold } from "react-icons/pi";
import axios from "axios";
import Confetti from "./Confetti";
import Select from "react-select";
import loadingImg from "../assets/Loading.json";
import loadingImgWhite from "../assets/Loading_White.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useUser } from "@clerk/clerk-react";
import toast from "react-hot-toast";
import { contentInput, linkedInInputs, redditInputs } from "../data/data.js";
import PromptResult from "./PromptResult.jsx";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const BACKEND_URL = process.env.REACT_APP_BACKEND_HOSTED_URL;

const TitleInput = ({ contentTitle, handlePromptDetails }) => {
  return (
    <input
      className={`border-2 p-[6px] ps-3 pe-3 lg:w-[735px] rounded-md w-full flex-1 focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200`}
      name="contentTitle"
      type="text"
      value={contentTitle}
      onChange={(e) => {
        handlePromptDetails(e);
      }}
      placeholder="Enter Title for Content or Generate"
      required
    />
  );
};

const TopicInput = ({ contentTopic, handlePromptDetails }) => {
  return (
    <input
      className={`border-2 p-[6px] ps-3 pe-3 lg:w-[735px] w-full rounded-md flex-1 focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200`}
      name="contentTopic"
      type="text"
      value={contentTopic}
      onChange={(e) => {
        handlePromptDetails(e);
      }}
      placeholder="Enter Topic for Content or Suggest keywords to generate"
      required
    />
  );
};

const CharlimitRange = ({ charLimit, values, handlePromptDetails }) => {
  return (
    <div className="w-full sm:w-auto sm:min-w-[250px] flex flex-col space-y-1">
      <label htmlFor="rangeChar" className="text-base font-semibold">
        Range: {charLimit ? charLimit : values.defaultValue} Characters (approx)
      </label>
      <input
        type="range"
        id="rangeChar"
        name="charLimit"
        min={values.min}
        max={values.max}
        onChange={handlePromptDetails}
        className="range range-xs w-56 [--range-shdw:#6035e0]"
        value={charLimit ? charLimit : values.defaultValue}
      />
    </div>
  );
};

const TextInput = ({ name, changeHandler, value, placeholder, required }) => {
  return (
    <input
      className="border-2 p-[6px] px-3 rounded-md col-span-1 w-full focus:border-[#6035e0] hover:border-gray-300 transition-all duration-200 outline-none"
      name={name}
      onChange={changeHandler}
      value={value}
      placeholder={placeholder}
      required={required}
    />
  );
};

// ACTUAL FUNCTION CALL
function GenerateArticles() {
  const location = useLocation();
  const recievedSavedArticleData = location.state || null;

  const defaultPromptDetails = {
    subRedditCategory:
      recievedSavedArticleData?.promptInput.subRedditCategory || "",
    postType: recievedSavedArticleData?.promptInput.postType || "",
    titleType: recievedSavedArticleData?.promptInput.titleType || "",
    engagementGoals:
      recievedSavedArticleData?.promptInput.engagementGoals || "",
    contentTitle: recievedSavedArticleData?.promptInput.contentTopic || "",
    contentTopic: recievedSavedArticleData?.promptInput.contentTopic || "",
    charLimit: 250,
    tone: recievedSavedArticleData?.promptInput.tone || "",
    keywords: recievedSavedArticleData?.promptInput.keywords || "",
    targetAudience: recievedSavedArticleData?.promptInput.targetAudience || "",
    includeLinks: recievedSavedArticleData?.promptInput.includeLinks || "",
    includeSeo: recievedSavedArticleData?.promptInput.includeSeo || "",
    callToAction: recievedSavedArticleData?.promptInput.callToAction || "",
    authorName: recievedSavedArticleData?.promptInput.authorName || "",
    useCase: recievedSavedArticleData?.promptInput.useCase || "",
  };

  const [productDetails, setProductDetails] = useState({
    content: recievedSavedArticleData?.platform || "",
  });
  const [promptDetails, setPromptDetails] = useState({
    productName: recievedSavedArticleData?.promptInput.productName || "",
    productCategory:
      recievedSavedArticleData?.promptInput.productCategory || "",
    productDescription:
      recievedSavedArticleData?.promptInput.productDescription || "",
    ...defaultPromptDetails,
  });

  const [selectedType, setSelectedType] = useState(
    recievedSavedArticleData?.promptInput.productName ||
      recievedSavedArticleData?.promptInput.productCategory ||
      recievedSavedArticleData?.promptInput.productDescription
      ? true
      : false
  );

  // Axios Requests
  const [promptData, setPromptData] = useState({
    output: recievedSavedArticleData?.promptResult || null,
    platform: recievedSavedArticleData?.platform || null,
  });

  const [fromHistory, setFromHistory] = useState(
    recievedSavedArticleData?.platform ? true : false
  );

  useEffect(() => {
    // setSelectedType(recievedSavedArticleData?.platform || false);
    // TO DO
    window.history.replaceState({}, "");
  }, []);

  const [loading, setLoading] = useState({
    content: false,
    topic: false,
    title: false,
  });

  const handleProductDetails = (itemValue, e) => {
    const { name } = e;
    const { value } = itemValue;

    if (value === "Product Description") {
      setSelectedType(true);
      document.getElementById("toggle-0").checked = true;
    }
    setProductDetails(() => ({
      [name]: value,
    }));
    setPromptDetails((prevInputs) => ({
      ...prevInputs,
      ...defaultPromptDetails,
    }));

    setPromptData(null);
  };

  const handleSelectType = () => {
    if (productDetails.content !== "Product Description") {
      setSelectedType(!selectedType);
    }
    if (selectedType === true) {
      promptDetails.productName = "";
      promptDetails.productCategory = "";
      promptDetails.productDescription = "";
    }
  };

  const handlePromptDetails = (itemValue, e) => {
    let nameData, valueData, typeData, checkedData;
    if (e) {
      const { name } = e;
      const { value } = itemValue;
      nameData = name;
      valueData = value;
    } else {
      const { name, value, type, checked } = itemValue.target;
      nameData = name;
      valueData = value;
      typeData = type;
      checkedData = checked;
    }
    setPromptDetails((prevInputs) => ({
      ...prevInputs,
      [nameData]: typeData === "checkbox" ? checkedData : valueData,
    }));
  };

  const handleResetAll = () => {
    setProductDetails({
      content: "",
    });
    setPromptDetails({
      productName: "",
      productCategory: "",
      productDescription: "",
      ...defaultPromptDetails,
    });
    setPromptData(null);

    const rdv = document.getElementById(`resetDropdownValue`);
    if (rdv) rdv.value = "";

    for (let i = 0; i <= 7; i++) {
      const rdvVar = document.getElementById(`resetDropdownValue${i}`);
      if (rdvVar) rdvVar.value = "";
    }
  };

  const userInfo = useUser();

  const submitGenerateContent = async (e) => {
    e.preventDefault();
    if (promptData !== null) {
      setPromptData(null);
    }
    setLoading((prevInputs) => ({ ...prevInputs, content: true }));
    const currentPlatform = productDetails.content;
    const currentPomptDetail = promptDetails;
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/contents/articles`,
        {
          productDetails,
          promptDetails,
          userEmail: userInfo.user.emailAddresses[0].emailAddress,
        }
      );
      if (response.data.completion) {
        setPromptData({
          output: response.data.completion,
          platform: currentPlatform,
          prompt: currentPomptDetail,
        });
      } else {
        toast.error("Something went wrong! Please Try Again");
      }
      setLoading((prevInputs) => ({ ...prevInputs, content: false }));
      setFromHistory(false);
      ReactGA.event({
        category: "Button Click",
        action: "Click to Generate Button Clicked",
        label: "Generate Section",
      });
    } catch (error) {
      toast.error("Something went wrong! Please Try Again");
      setLoading((prevInputs) => ({ ...prevInputs, content: false }));
    }
  };

  // Range Slider & Auto-Generate
  const submitGenerateTopicAndTitle = async (generateFor) => {
    try {
      setLoading((prevInputs) =>
        generateFor === "Topic"
          ? { ...prevInputs, topic: true }
          : { ...prevInputs, title: true }
      );
      const response = await axios.post(
        `${BACKEND_URL}/api/contents/generate/titleORtopic`,
        {
          productDetails,
          promptDetails,
          generateFor,
          userEmail: userInfo.user.emailAddresses[0].emailAddress,
        }
      );
      if (response.data.completion) {
        setPromptDetails((prevInputs) =>
          generateFor === "Topic"
            ? { ...prevInputs, contentTopic: response.data.completion }
            : { ...prevInputs, contentTitle: response.data.completion }
        );
      } else {
        toast.error("Something went wrong! Please Try Again");
      }
      setLoading((prevInputs) =>
        generateFor === "Topic"
          ? { ...prevInputs, topic: false }
          : { ...prevInputs, title: false }
      );
      ReactGA.event({
        category: "Button Click",
        action: "Title/Topic Button Clicked",
        label: "Generate Section",
      });
    } catch (error) {
      toast.error("Something went wrong! Please Try Again");
      setLoading((prevInputs) =>
        generateFor === "Topic"
          ? { ...prevInputs, topic: false }
          : { ...prevInputs, title: false }
      );
    }
  };

  const AutoGenerate = ({ clickForHandler, loadingHandler, disabled }) => {
    return (
      <div
        onClick={
          !disabled
            ? () => submitGenerateTopicAndTitle(clickForHandler)
            : () => {}
        }
        className={`border text-[15px] md:hover:scale-105 md:active:scale-100 h-[42px] font-semibold text-white shadow-sm hover:shadow-md transition-all duration-200 px-4 py-2 rounded-md flex justify-center flex-row items-center w-full xs:w-36 bg-[#6035e0] active:scale-95 ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {clickForHandler === "Topic" &&
          (loadingHandler.topic ? (
            <Player
              src={loadingImgWhite}
              className="player flex flex-row justify-center items-center h-10 scale-150"
              autoplay
              loop
            />
          ) : (
            "Generate"
          ))}
        {clickForHandler === "Title" &&
          (loadingHandler.title ? (
            <Player
              src={loadingImgWhite}
              className="player flex flex-row justify-center items-center h-10 scale-150"
              autoplay
              loop
            />
          ) : (
            "Generate"
          ))}
      </div>
    );
  };

  return (
    <div className="sm:pb-16 md:p-6 md:bg-slate-50 flex-1 sm:bg-gray-100 min-w-0">
      <div className="bg-white p-4 sm:p-6 flex gap-3 md:border rounded-md">
        <form
          className="flex flex-col justify-center gap-3 w-full md:w-11/12 lg:w-9/12 "
          onSubmit={submitGenerateContent}
        >
          <div className="flex flex-col gap-3 w-full ">
            <p className="font-semibold text-[15px]">Generate New Article</p>
            <div className="flex flex-col justify-center gap-3 w-full">
              <Select
                id="contentDropdown"
                className="bg-slate-50 rounded-md w-full sm:w-72 h-fit"
                name="content"
                onChange={handleProductDetails}
                isSearchable
                required
                value={{
                  label:
                    productDetails.content || "Content to be generated for",
                }}
                options={contentInput.content}
              />
              <div className="form-check flex gap-3">
                <p className="font-semibold text-[15px]">
                  Generate for a Product
                </p>
                <input
                  id={`toggle-0`}
                  onChange={() => handleSelectType()}
                  type="checkbox"
                  className={`toggle border-[#6035e0] ${
                    selectedType
                      ? "bg-white hover:bg-[#f3f4f6] [--tglbg:#6035e0] hover:[--tglbg:#6035e0]"
                      : "bg-[#6035e0] hover:bg-[#6035e0] hover:[--tglbg:#f3f4f6]"
                  }`}
                  disabled={productDetails.content === "Product Description"}
                  defaultChecked={selectedType}
                />
              </div>
            </div>
            {selectedType === true && (
              <div className="flex flex-col gap-3">
                <p className="font-semibold text-[15px]">Product Details</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <TextInput
                    name="productName"
                    changeHandler={handlePromptDetails}
                    value={promptDetails.productName}
                    placeholder="Enter Product Name"
                    required
                  />
                  <TextInput
                    name="productCategory"
                    changeHandler={handlePromptDetails}
                    value={promptDetails.productCategory}
                    placeholder="Product's Category"
                    required
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <TextInput
                    name="productDescription"
                    changeHandler={handlePromptDetails}
                    value={promptDetails.productDescription}
                    placeholder="Enter Product Description"
                    required
                  />
                </div>
              </div>
            )}

            {productDetails.content && (
              <div className="space-y-3">
                <div className="flex flex-col justify-center gap-3">
                  <div className="space-y-3">
                    {productDetails.content && (
                      <p className="font-semibold text-[15px]">
                        Content Details
                      </p>
                    )}

                    {(productDetails.content === "Reddit" ||
                      productDetails.content === "Medium" ||
                      productDetails.content === "Twitter" ||
                      productDetails.content === "Blog" ||
                      productDetails.content === "LinkedIn") && (
                      <div className="flex flex-col xs:flex-row sm:items-center gap-3">
                        <TopicInput
                          contentTopic={promptDetails.contentTopic}
                          handlePromptDetails={handlePromptDetails}
                        />
                        <AutoGenerate
                          clickForHandler={"Topic"}
                          loadingHandler={loading}
                          disabled={
                            loading.content ||
                            loading.title ||
                            loading.topic ||
                            (promptDetails.contentTopic.length == 0
                              ? true
                              : false)
                          }
                        />
                      </div>
                    )}

                    {(productDetails.content === "Reddit" ||
                      productDetails.content === "Blog" ||
                      productDetails.content === "Medium") && (
                      <div className="flex flex-col xs:flex-row xs:items-center gap-3">
                        <TitleInput
                          contentTitle={promptDetails.contentTitle}
                          handlePromptDetails={handlePromptDetails}
                        />
                        <AutoGenerate
                          clickForHandler={"Title"}
                          loadingHandler={loading}
                          disabled={
                            loading.content ||
                            loading.title ||
                            loading.topic ||
                            ((promptDetails.contentTopic.length == 0
                              ? true
                              : false) &&
                              (promptDetails.contentTitle.length == 0
                                ? true
                                : false))
                          }
                        />
                      </div>
                    )}

                    <p className="font-semibold text-[15px] text-[#6035e0]">
                      Below filters are optional* but can improve results.
                    </p>

                    {productDetails.content === "Reddit" && (
                      <div className="space-y-2">
                        <p className="font-semibold text-[15px]">
                          Reddit Community Details
                        </p>
                        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-3">
                          <TextInput
                            name="subRedditCategory"
                            changeHandler={handlePromptDetails}
                            value={promptDetails.subRedditCategory}
                            placeholder="SubCategory"
                          />
                          <Select
                            className="bg-slate-50 rounded-md col-span-1 h-fit"
                            name="postType"
                            onChange={handlePromptDetails}
                            id="resetDropdownValue2"
                            isSearchable
                            options={redditInputs.postType}
                            value={{
                              label: promptDetails.postType || "Post-Type",
                            }}
                          />
                          <Select
                            className="bg-slate-50 rounded-md col-span-1 h-fit"
                            name="titleType"
                            onChange={handlePromptDetails}
                            id="resetDropdownValue3"
                            isSearchable
                            options={redditInputs.titleType}
                            value={{
                              label: promptDetails.titleType || "Title-Type",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {(productDetails.content === "Reddit" ||
                      productDetails.content === "Blog" ||
                      productDetails.content === "Medium") && (
                      <CharlimitRange
                        charLimit={promptDetails.charLimit}
                        values={{
                          min: "100",
                          max: "4000",
                          defaultValue: "250",
                        }}
                        handlePromptDetails={handlePromptDetails}
                      />
                    )}

                    {(productDetails.content === "Product Description" ||
                      productDetails.content === "LinkedIn" ||
                      productDetails.content === "Twitter") && (
                      <CharlimitRange
                        charLimit={promptDetails.charLimit}
                        values={{
                          min: "100",
                          max: "2500",
                          defaultValue: "250",
                        }}
                        handlePromptDetails={handlePromptDetails}
                      />
                    )}
                  </div>

                  <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-3">
                    {productDetails.content && (
                      <Select
                        className="bg-slate-50 rounded-md shadow-sm col-span-1 h-fit"
                        name="engagementGoals"
                        onChange={handlePromptDetails}
                        id="resetDropdownValue3"
                        options={linkedInInputs.engagementGoals}
                        value={{
                          label:
                            promptDetails.engagementGoals || "Engagement-Goals",
                        }}
                      />
                    )}

                    {productDetails.content && (
                      <Select
                        className="bg-slate-50 rounded-md shadow-sm col-span-1 h-fit"
                        name="tone"
                        onChange={handlePromptDetails}
                        id="resetDropdownValue2"
                        isSearchable
                        options={linkedInInputs.tone}
                        value={{
                          label: promptDetails.tone || "Tone",
                        }}
                      />
                    )}

                    {(productDetails.content === "Product Description" ||
                      productDetails.content === "Blog" ||
                      productDetails.content === "Medium" ||
                      productDetails.content === "LinkedIn" ||
                      productDetails.content === "Twitter") && (
                      <>
                        <TextInput
                          name="callToAction"
                          changeHandler={handlePromptDetails}
                          value={promptDetails.callToAction}
                          placeholder="Call to Action(CTA) like Visit a website"
                        />

                        <TextInput
                          name="targetAudience"
                          changeHandler={handlePromptDetails}
                          value={promptDetails.targetAudience}
                          placeholder="Target Audience"
                        />
                      </>
                    )}

                    {(productDetails.content === "Reddit" ||
                      productDetails.content === "Blog" ||
                      productDetails.content === "Medium") && (
                      <TextInput
                        name="keywords"
                        changeHandler={handlePromptDetails}
                        value={promptDetails.keywords}
                        placeholder="Keywords"
                      />
                    )}

                    {productDetails.content === "Blog" && (
                      <TextInput
                        name="authorName"
                        changeHandler={handlePromptDetails}
                        value={promptDetails.authorName}
                        placeholder="Author Name"
                      />
                    )}
                  </div>
                  {/* </div> */}

                  {(productDetails.content === "Product Description" ||
                    productDetails.content === "Blog" ||
                    productDetails.content === "Medium" ||
                    productDetails.content === "LinkedIn" ||
                    productDetails.content === "Twitter") && (
                    <div className="space-y-2">
                      <p className="font-semibold text-[15px]">
                        Include Options
                      </p>
                      <div className="gap-3 flex flex-col sm:flex-row">
                        <div className="form-control">
                          <label className="label justify-start cursor-pointer space-x-2 p-0">
                            <input
                              type="checkbox"
                              id="checkboxlabel"
                              name="includeLinks"
                              className="checkbox [--chkbg:#6035e0] [--chkfg:white]"
                              onClick={handlePromptDetails}
                            />
                            <span className="text-[15px] font-medium">
                              Include Links
                            </span>
                          </label>
                        </div>
                        {productDetails.content === "Blog" && (
                          <div className="form-control">
                            <label className="label justify-start cursor-pointer space-x-2 p-0">
                              <input
                                type="checkbox"
                                id="checkboxSeolabel"
                                name="includeSeo"
                                className="checkbox [--chkbg:#6035e0] [--chkfg:white]"
                                onClick={handlePromptDetails}
                              />
                              <span className="text-[15px] font-medium">
                                Include SEO Friendly
                              </span>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Buttons */}
          <div className="flex flex-col sm:flex-row items-center gap-3 mt-3">
            <button
              className={`order-2 truncate sm:order-1 border h-10 shadow-sm md:hover:scale-105 active:scale-105 md:active:scale-100 hover:shadow-md transition-all duration-200 px-2 lg:px-4 py-2 rounded-md flex justify-center gap-1 flex-row items-center w-full sm:w-48 sm:max-w-[220px] bg-primary ${
                loading.content ||
                loading.title ||
                loading.topic ||
                (productDetails.content.length == 0 ? true : false)
                  ? "cursor-not-allowed"
                  : ""
              }`}
              disabled={
                loading.content ||
                loading.title ||
                loading.topic ||
                productDetails.content.length == 0
                  ? true
                  : false
              }
            >
              <PiConfettiBold className="text-white w-5 h-5" />
              <p className="text-white text-[15px] font-semibold">
                Click to Generate
              </p>
            </button>
            <div
              className={`order-3 sm:order-2 shadow-sm h-10 justify-center hover:shadow-md transition-all duration-200 px-2 lg:px-4 py-2 rounded-md flex gap-3 flex-row items-center w-full sm:w-36 sm:max-w-[220px] bg-[#6035e0] ${
                loading.content || loading.title || loading.topic
                  ? "cursor-not-allowed"
                  : "cursor-pointer md:hover:scale-105 active:scale-105 md:active:scale-100 "
              }`}
              onClick={() =>
                loading.content || loading.title || loading.topic
                  ? null
                  : handleResetAll()
              }
            >
              <p className="text-white text-[15px] font-semibold">Reset</p>
            </div>
          </div>
        </form>
      </div>
      {loading.content ? (
        <Player
          src={loadingImg}
          className="player w-12 scale-150"
          autoplay
          loop
        />
      ) : (
        promptData?.output && (
          <div className="relative">
            <Confetti />
            <PromptResult
              promptData={promptData.output}
              promptDetails={promptData.prompt}
              platform={promptData.platform}
              fromHistory={fromHistory}
            />
          </div>
        )
      )}
    </div>
  );
}

export default GenerateArticles;
