import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import LeftSidebar from "../components/LeftSidebar";
import GenerateArticles from "../components/GenerateArticles";
import SavedArticles from "../components/SavedArticles";
import RegenerateArticles from "../components/RegenerateArticles";

function Dashboard() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[2] || "";

  return (
    <div className="relative">
      <Navbar />
      <div className="flex flex-col-reverse md:flex-row ">
        <LeftSidebar />
        {pathname === "" && <GenerateArticles />}
        {pathname === "history" && <SavedArticles />}
        {pathname === "regenerate" && <RegenerateArticles />}
      </div>
    </div>
  );
}

export default Dashboard;
