import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="p-6 sm:p-16 pt-8 pb-8 flex flex-col gap-6 sm:gap-10 bg-[#6035e0]">
      <Link
        to={"/"}
        className="text-white flex flex-row items-center gap-2 w-fit"
      >
        <MdKeyboardBackspace size={24} />
        <p>Back</p>
      </Link>
      <p className="font-bold text-3xl sm:text-5xl text-white">
        Privacy Policy
      </p>
      <div className="text-white flex flex-col gap-3 pb-5">
        <p>
          At TypeFast AI, we understand the importance of your privacy and are committed to protecting it. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our services.
        </p>
        <p className="font-bold text-xl">1. Information We Collect</p>
        <p>
          Personal Information: When you sign up for TypeFast AI, we may collect personal information such as your name, email address, and other relevant details to create and manage your account.
        </p>
        <p>
          Usage Information: We may collect information about how you interact with our services, including your browsing activities, IP address, device information, and usage patterns.
        </p>
        <p className="font-bold text-xl">2. How We Use Your Information</p>
        <p>
          <span className="font-bold">2.1 Personalization:</span> We use your personal information to personalize your experience and provide customized content, features, and recommendations.
        </p>
        <p>
          <span className="font-bold">2.2 Communication:</span> We may use your contact information to communicate with you about your account, updates, promotions, and other relevant information related to TypeFast AI.
        </p>
        <p>
          <span className="font-bold">2.3 Improvements:</span> We analyze usage data to improve our services, optimize performance, and develop new features and functionalities.
        </p>
        <p className="font-bold text-xl">3. Information Sharing</p>
        <p>
          <span className="font-bold">3.1 Third-Party Service Providers:</span>{" "}
          We may share your information with third-party service providers who assist us in delivering our services, such as hosting providers, analytics services, and customer support platforms.
        </p>
        <p>
          <span className="font-bold">3.2 Legal Compliance:</span> We may disclose your information in response to legal requests, court orders, or to comply with applicable laws and regulations.
        </p>
        <p className="font-bold text-xl">4. Data Security</p>
        <p>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
        </p>
        <p>
          However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        </p>
        <p className="font-bold text-xl">5. Your Choices</p>
        <p>
          You have the right to access, update, or delete your personal information stored in your TypeFast AI account.
        </p>
        <p>
          You can manage your communication preferences and opt-out of promotional emails or notifications.
        </p>
        <p className="font-bold text-xl">6. Changes to This Privacy Policy</p>
        <p>
          We may update this Privacy Policy from time to time, and any changes will be reflected on this page.
        </p>
        <p>
          We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.
        </p>
        <p className="font-bold text-xl">7. Contact Us</p>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at diyanshu0360@gmail.com.
        </p>
        <p>
          By using TypeFast AI, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.
        </p>
        <p>Last Updated: 11 December, 2023</p>
      </div>
    </div>
  );
}

export default Privacy;
