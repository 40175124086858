import React from "react";
import dashboardImg from "../../assets/Dashboard1.png";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

function SubscribeAds() {
  const navigate = useNavigate();
  const userInfo = useUser();

  return (
    <div className="w-full bg-primary">
      <div className="max-w-7xl w-full m-auto px-5 py-10 gap-10 text-white grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col justify-center gap-5 sm:gap-10 col-span-2">
          <div className="space-y-1 sm:space-y-2">
            <p className="cursive text-white text-lg text-center">Coming Soon</p>
            <p className="text-3xl md:text-5xl font-bold text-center">
              TypeFast AI Chrome Extension
            </p>
          </div>
          <p className="text-center w-full lg:w-1/2 m-auto leading-7">
            Exciting news! TypeFast AI is introducing a Chrome Extension soon, making content creation even easier. Generate compelling articles directly on your favorite platforms with just a click. Stay tuned for a hassle-free, free-to-use experience – your effortless content creation journey is about to get a whole lot simpler with TypeFast AI's upcoming Chrome Extension!
          </p>
          <button
            onClick={
              userInfo.user
                ? () => navigate("/dashboard")
                : () => navigate("/sign-up")
            }
            className="bg-white hover:scale-105 transition-all duration-100 hover:shadow-md w-fit text-black p-3 ps-5 pe-5 rounded-full shadow-sm font-semibold m-auto"
          >
            Ready to Explore
          </button>
        </div>
      </div>
    </div>
  );
}

export default SubscribeAds;
