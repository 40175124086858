import React, { useState } from "react";
import brandLogo from "../assets/TypeFast AI (1).png";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { UserButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { PiConfettiBold } from "react-icons/pi";
import { MdFeaturedPlayList } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";

const links = [
  {
    title: "Generate Article",
    link: "",
    icon: <PiConfettiBold className="w-5 h-5 md:w-6 md:h-6" />,
  },
  // { title: "Generate  Articles", link: '', icon: <PiConfettiBold className="w-6 h-6" /> },
  {
    title: "Rewrite Article",
    link: "regenerate",
    icon: <MdFeaturedPlayList className="w-5 h-5 md:w-6 md:h-6" />,
  },
  {
    title: "History",
    link: "history",
    icon: <IoDocuments className="w-5 h-5 md:w-6 md:h-6" />,
  },
];

function Navbar() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[2] || "";
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const crossVariants = {
    open: { rotate: 90 },
    closed: { rotate: -90 },
  };

  const menuIcon = (
    <motion.div
      variants={crossVariants}
      animate={isMobileMenuOpen ? "open" : "closed"}
      transition={{ duration: 0.3 }}
      className="text-white cursor-pointer md:hidden"
      onClick={toggleMobileMenu}
    >
      <FaTimes size={24} />
    </motion.div>
  );

  const barsIcon = (
    <FaBars
      className="text-white cursor-pointer md:hidden"
      size={24}
      onClick={toggleMobileMenu}
    />
  );

  return (
    <header
      className={`flex flex-col bg-[#6035e0] z-50 top-0 left-0 right-0  ${
        isMobileMenuOpen ? "fixed top-0 bottom-0" : "sticky"
      }`}
    >
      <div className="sticky left-0 right-0 flex justify-between p-5 pt-3 pb-3 sm:p-5 sm:pt-3 sm:pb-3 items-center top-0 bg-[#6035e0] z-50">
        <Link
          to={"/dashboard"}
          className="flex gap-1 transition-all duration-200 items-center w-fit hover:scale-105 cursor-pointer"
        >
          <img src={brandLogo} alt="No Img" className="w-6 h-6 rounded-md" />
          <p className="text-xl font-semibold text-white">ypeFast AI</p>
        </Link>
        <div className="flex gap-2 items-center w-fit">
          <UserButton afterSignOutUrl="/" />
          <div className="block md:hidden border h-8 opacity-70" />
          {isMobileMenuOpen ? menuIcon : barsIcon}
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="flex flex-col md:hidden h-full bg-white p-3 text-black">
          <div className="space-y-2">
            <p className="font-semibold text-sm">Started Here</p>
            {links.map((link, index) => (
              <Link
                key={index}
                style={{
                  backgroundColor: link.link === pathname ? "#6035e0" : "",
                  color: link.link === pathname ? "white" : "black",
                }}
                className="p-2 ps-4 cursor-pointer hover:shadow-sm border pe-4 rounded-md flex justify-start gap-3 flex-row items-center"
                to={`/dashboard${link.link ? "/" + link.link : ""}`}
                onClick={toggleMobileMenu}
              >
                {link.icon}
                <p className="font-semibold">{link.title}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </header>
  );
}

export default Navbar;
