import React, { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import noUser from "../assets/No_User.json";
import toast from "react-hot-toast";

const categoryItem = [
  { name: "All", icon: null },
  {
    name: "Reddit",
    icon: createSocialIcon("https://reddit.com", "18px"),
  },
  {
    name: "Twitter",
    icon: createSocialIcon("https://x.com", "18px"),
  },
  {
    name: "LinkedIn",
    icon: createSocialIcon("https://linkedin.com", "18px"),
  },
  {
    name: "Medium",
    icon: createSocialIcon("https://medium.com", "18px"),
  },
  { name: "Blog", icon: createColoredIcon("bg-yellow-400", "B") },
  { name: "Product Description", icon: createColoredIcon("bg-blue-400", "P") },
  { name: "Regenerate", icon: createColoredIcon("bg-pink-400", "R") },
];

function createSocialIcon(url, size) {
  return url ? (
    <SocialIcon
      url={url}
      style={{
        width: size,
        height: size,
        pointerEvents: "none",
      }}
    />
  ) : null;
}

function createColoredIcon(color, text) {
  return (
    <div className={`rounded-full ${color}`}>
      <p className="w-4 h-4 flex flex-row items-center justify-center text-sm font-semibold">
        {text}
      </p>
    </div>
  );
}

const ArticleCard = ({
  promptData,
  platform,
  dateCreated,
  item,
  deleteHandler,
  promptInput,
}) => {
  console.log(item);
  const navigate = useNavigate();

  // PROMPT TEXT FORMATIING
  const data = promptData?.split(" ").slice(0, 12);
  const newTextData = data.join(" ");

  // DATE FORMATIING
  function dateToYMD(date) {
    const strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = date.getDate().toString().padStart(2, "0");
    const m = strArray[date.getMonth()];
    const y = date.getFullYear();
    return `${d}-${m}-${y}`;
  }
  const newDate = dateToYMD(new Date(dateCreated));

  return (
    <div className="bg-white border rounded-md p-3 w-full sm:w-80 md:w-full lg:w-80 h-fit flex flex-col justify-start gap-2 hover:shadow-sm duration-200 transition-all">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-1">
          {categoryItem.map((item) => {
            if (item.name === platform) return item.icon;
          })}
          <p className="text-sm font-semibold">{platform}</p>
        </div>
        <p className="text-sm font-medium">{newDate}</p>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div>
          <p className="font-semibold overflow-hidden">{newTextData} ...</p>
        </div>
        <div className="flex flex-row justify-between mt-1 sm:mt-2">
          <button
            onClick={
              platform === "Regenerate"
                ? () =>
                    navigate("/dashboard/regenerate", {
                      state: item,
                    })
                : () =>
                    navigate("/dashboard/", {
                      state: item,
                    })
            }
            className="border text-white rounded-3xl p-1 ps-4 pe-4 text-sm font-semibold transition-all duration-200 hover:shadow-sm hover:scale-105 bg-[#6035e0]"
          >
            View
          </button>
          <MdDelete
            onClick={() => deleteHandler(item)}
            className="text-red-500 z-10 border rounded-full p-1 h-7 w-7 hover:scale-110 hover:shadow-md transition-all duration-200 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

function SavedArticles() {
  const userInfo = useUser();
  const [allData, setAllData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const allArticlesAPI = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOSTED_URL}/api/saved/getall`,
        { userEmail: userInfo.user.emailAddresses[0].emailAddress }
      );
      setAllData(response.data);
    } catch (error) {
      toast.error("Something went wrong! Please Try Again");
    }
  };

  useEffect(() => {
    if (userInfo.user) {
      allArticlesAPI();
    }
  }, []);

  const deleteHandler = async (item) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOSTED_URL}/api/saved/delete`,
        {
          userEmail: userInfo.user.emailAddresses[0].emailAddress,
          articleID: item._id,
        }
      );
      console.log(response.data.allArticles);
      setAllData(response.data.allArticles);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sm:pb-16 md:p-6 md:bg-slate-50 flex-1 min-w-0">
      <div className="bg-white md:border rounded-md flex flex-col gap-3 h-full">
        <div className="flex flex-row justify-start gap-2 items-center p-2 pt-4 sm:p-4 sm:flex-wrap overflow-auto sm:overflow-hidden no-scrollbar">
          {categoryItem.map((item, index) => (
            <button
              key={index}
              onClick={async () => {
                setAllData([]);
                if (item.name === "All") {
                  setSelectedCategory("All");
                  return allArticlesAPI();
                }
                setSelectedCategory(item.name);
                await axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_HOSTED_URL}/api/saved/getall`,
                    {
                      userEmail: userInfo.user.emailAddresses[0].emailAddress,
                    }
                  )
                  .then((response) =>
                    setAllData([
                      ...response.data.filter(
                        (prevInputs) => prevInputs.platform == item.name
                      ),
                    ])
                  );
              }}
              className={` ${item.name === selectedCategory
                  ? " border-[#6035e0] border bg-[#6035e0] bg-opacity-20 "
                  : "bg-white border-gray-300 hover:border-gray-400 border "
                }text-sm md:hover:scale-105 active:scale-105 md:active:scale-100 hover:shadow-md min-w-fit font-semibold rounded-3xl ps-3 pe-3 p-1 flex flex-row items-center justify-start gap-1 transition-all duration-200`}
            >
              {item.icon || ""}
              <p>{item.name}</p>
            </button>
          ))}
        </div>
        <div
          className={`p-2 pt-0 sm:p-4 sm:pt-0 flex flex-row ${allData?.length ? "justify-start" : "justify-center h-full"
            } flex-wrap gap-3`}
        >
          {allData?.length ? (
            allData.map((item, index) => (
              <>
                <ArticleCard
                  key={index}
                  promptData={item.promptResult}
                  promptInput={item.promptInput}
                  platform={item.platform}
                  dateCreated={item.dateCreated}
                  item={item}
                  deleteHandler={deleteHandler}
                />
              </>
            ))
          ) : (
            <div className="pt-20 md:pt-0">
              <Player
                src={noUser}
                className="player w-20 scale-150"
                autoplay
                loop
              />
              <p className="text-center font-semibold text-xl">
                No Saved Articles
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedArticles;
