import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import React, { useState } from "react";
import { FiSave } from "react-icons/fi";
import { FaRegCopy } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";
import ReactGA from "react-ga";

function PromptResult({ promptData, platform, promptDetails, fromHistory }) {
  const promptDataArray = promptData.split("\n");
  const dataList = [];
  promptDataArray.map((item) => {
    dataList.push(item);
  });

  // HANDLING COPY AND SAVE ACTIONS
  const [copyBtn, setCopyBtn] = useState("Copy");
  console.log(fromHistory);
  const [saveBtn, setSaveBtn] = useState(fromHistory ? "Saved" : "Save");
  const userInfo = useUser();
  const userEmail = userInfo.user.emailAddresses[0].emailAddress;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(promptData);
      setCopyBtn("Copied");
      setTimeout(() => {
        setCopyBtn("Copy");
      }, 3000);
    } catch (err) {}
  };

  // SAVED ACTION
  const savedDataAPI = async () => {
    try {
      setSaveBtn("Saved");
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOSTED_URL}/api/saved/save/result`,
        {
          userEmail: userEmail,
          promptResult: promptData,
          platform: platform,
          promptDetails: promptDetails,
        }
      );
      ReactGA.event({
        category: "Button Click",
        action: "Saved Button Clicked",
        label: "History Section",
      });
    } catch (error) {
      setSaveBtn("Save");
    }
  };

  return (
    <div className="bg-white sm:border sm:rounded-md p-4 sm:p-6 sm:mt-5 sm:mb-3 flex flex-col justify-center gap-3">
      <div className="border mb-3 sm:hidden" />
      <div className="flex sm:flex-row flex-col sm:items-center justify-between gap-3 sm:gap-5">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row items-center gap-1">
            {platform === "Reddit" ? (
              <SocialIcon
                url="https://reddit.com"
                style={{
                  width: "20px",
                  height: "20px",
                  pointerEvents: "none",
                }}
              />
            ) : platform === "Twitter" ? (
              <SocialIcon
                url="https://x.com"
                style={{
                  width: "20px",
                  height: "20px",
                  pointerEvents: "none",
                }}
              />
            ) : platform === "LinkedIn" ? (
              <SocialIcon
                url="https://linkedin.com"
                style={{
                  width: "20px",
                  height: "20px",
                  pointerEvents: "none",
                }}
              />
            ) : platform === "Medium" ? (
              <SocialIcon
                url="https://medium.com"
                style={{
                  width: "20px",
                  height: "20px",
                  pointerEvents: "none",
                }}
              />
            ) : platform === "Blog" ? (
              <div className="border rounded-full bg-yellow-400">
                <p className="w-5 h-5 flex flex-row items-center justify-center text-sm font-semibold">
                  B
                </p>
              </div>
            ) : platform === "Regenerate" ? (
              <div className="border rounded-full bg-pink-400">
                <p className="w-5 h-5 flex flex-row items-center justify-center text-white text-sm font-semibold">
                  R
                </p>
              </div>
            ) : (
              <div className="border rounded-full bg-pink-400">
                <p className="w-5 h-5 flex flex-row items-center justify-center text-white text-sm font-semibold">
                  P
                </p>
              </div>
            )}
          </div>
          <p className="font-semibold w-fit">{platform}</p>
        </div>
        <div className="flex flex-row items-center justify-between sm:justify-end gap-1 lg:gap-5">
          <button
            disabled={saveBtn === "Saved"}
            onClick={saveBtn === "Saved" ? null : savedDataAPI}
            className={`${
              saveBtn === "Saved"
                ? "cursor-not-allowed bg-slate-50"
                : "cursor-pointer bg-white"
            } text-[#6035e0] badge badge-md flex flex-row text-nowrap items-center gap-1 font-semibold transition-all duration-200 p-1 ps-4 pe-4 rounded-full border h-fit hover:border-slate-200 hover:scale-105 hover:shadow-sm`}
          >
            {saveBtn === "Saved" ? (
              "Already Saved"
            ) : (
              <>
                <FiSave className="w-4 h-4" />
                Save
              </>
            )}
          </button>
          <button
            disabled={copyBtn === "Copied"}
            onClick={handleCopyClick}
            className={`${
              copyBtn === "Copied"
                ? "cursor-not-allowed bg-slate-50"
                : "cursor-pointer bg-white"
            } text-[#6035e0] badge badge-md flex text-nowrap  flex-row items-center gap-1 font-semibold transition-all duration-200 p-1 ps-4 pe-4 rounded-full border h-fit hover:border-slate-200 hover:scale-105 hover:shadow-sm`}
          >
            {copyBtn === "Copied" ? (
              "Copied"
            ) : (
              <>
                <FaRegCopy className="w-4 h-4" />
                Copy
              </>
            )}
          </button>
        </div>
      </div>
      <div className="border bg-slate-50 p-3 sm:p-6 rounded-md flex flex-col gap-5">
        {dataList.map((item, index) => (
          <>
            {item.trim() !== "" && (
              <p key={index} className="text-black">
                {item}
              </p>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default PromptResult;
