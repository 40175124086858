import React from "react";
import Navbar from "../components/landingpage/Navbar";
import Footer from "../components/landingpage/Footer";
import Subscribe from "../components/landingpage/Subscribe";
import Compare from "../components/landingpage/Compare";
import Hero from "../components/landingpage/Hero";
import Features from "../components/landingpage/Features";
import Testimonial from "../components/landingpage/Testimonial";
import Faqs from "../components/landingpage/Faqs";
import SubscribeAds from "../components/landingpage/SubscribeAds";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Features />
      <Compare />
      <Subscribe />
      <Testimonial />
      <Faqs />
      <SubscribeAds />
      <Footer />
    </div>
  );
}

export default Home;
