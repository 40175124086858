import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/landingpage/Navbar";
import SubscribeAds from "../components/landingpage/SubscribeAds";
import Footer from "../components/landingpage/Footer";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const BACKEND_URL = process.env.REACT_APP_BACKEND_HOSTED_URL;

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/blog/${id}`);

        if (response.status === 200) {
          console.log(response.data.data);
          setBlog(response.data.data);
        }
      } catch (error) { }
    };

    fetchBlog();
  }, []);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <Navbar />
      <div className="w-full max-w-3xl m-auto">
        <div className="w-full p-8 sm:py-16 md:pb-8 md:pt-16 flex flex-col gap-5 justify-center text-center items-center">
          <h1 className="text-3xl md:text-5xl font-bold w-full">
            {blog.title}
          </h1>
          {blog?.author && <p className="text-gray-500 text-sm mt-2">
            {blog.author} • {formatDate(blog.createdAt)} • {blog.minRead} min
            read
          </p>}
          {blog?.title && <div className="flex gap-2 justify-center w-full">
            <FacebookShareButton
              url={`https://typefastai.com/blogs/${id}`}
              quote={blog.title}
              hashtag="#muo"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://typefastai.com/blogs/${id}`}
              title={blog.title}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`https://typefastai.com/blogs/${id}`}
              title={blog.title}
              source="TypeFast AI"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton
              url={`https://typefastai.com/blogs/${id}`}
              subject={blog.title}
              body={`Check out this interesting blog post: ${blog.title}`}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>}
        </div>
        <div className="px-5 md:p-0">
          {blog?.coverImage && <img
            src={blog.coverImage}
            alt="Blog Cover"
            className="w-full aspect-[3/2] rounded-md"
          />}
        </div>
        <div
          className="p-5 md:px-0 space-y-4"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        ></div>
      </div>
      {/* <section className="max-w-7xl w-full m-auto mt-10">
        <h1 className="text-3xl sm:text-4xl font-bold text-center">
          You may also want to read
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <BlogCard
            title={'How to write a blog post'}
            description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, iure!'}
            date={'March 3, 2024'}
            readTime={'4 min read'}
            imgSrc={'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d'}
            imgAlt={'Blog COver Image'} />
          <BlogCard
            title={'How to write a blog post'}
            description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, iure!'}
            date={'March 3, 2024'}
            readTime={'4 min read'}
            imgSrc={'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d'}
            imgAlt={'Blog COver Image'} />
          <BlogCard
            title={'How to write a blog post'}
            description={'Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus, iure!'}
            date={'March 3, 2024'}
            readTime={'4 min read'}
            imgSrc={'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d'}
            imgAlt={'Blog COver Image'} />
        </div>
      </section> */}
      <SubscribeAds />
      <Footer />
    </div>
  );
}

export default BlogDetail;
