import React, { useState } from "react";
import { motion } from "framer-motion";
import brandLogo from "../../assets/TypeFast AI (1).png";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // Import the menu and close icons
import { HashLink } from "react-router-hash-link";
import { useUser } from "@clerk/clerk-react";

const Button = ({ link, text }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(link)}
      className="bg-white text-primary font-semibold rounded-md p-2 ps-5 pe-5 transition-all duration-10 hover:scale-105 shadow-sm hover:shadow-md mt-4 md:mt-0"
    >
      {text}
    </button>
  )
}

function Navbar() {
  const userInfo = useUser();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const crossVariants = {
    open: { rotate: 90 },
    closed: { rotate: -90 },
  };

  const menuIcon = (
    <motion.div
      variants={crossVariants}
      animate={isMobileMenuOpen ? "open" : "closed"}
      transition={{ duration: 0.3 }}
      className="text-white cursor-pointer md:hidden"
      onClick={toggleMobileMenu}
    >
      <FaTimes size={24} />
    </motion.div>
  );

  const barsIcon = (
    <FaBars
      className="text-white cursor-pointer md:hidden"
      size={24}
      onClick={toggleMobileMenu}
    />
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="sticky top-0 left-0 right-0 w-full bg-primary"
    >
      <div className="max-w-7xl w-full flex flex-col md:flex-row md:justify-between md:items-center m-auto p-5">
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center">
            <Link
              to={"/"}
              className="flex gap-1 transition-all duration-100 items-center w-fit hover:scale-105 cursor-pointer"
            >
              <img src={brandLogo} alt="No Img" className="w-6 h-6 rounded-md" />
              <p className="text-xl font-semibold text-white">ypeFast AI</p>
            </Link>
          </div>
          {/* Mobile Toggle Button */}
          {isMobileMenuOpen ? menuIcon : barsIcon}
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:flex md:gap-10 md:flex-row md:items-center ${isMobileMenuOpen ? "flex flex-col mt-10 gap-3 text-center" : "hidden"
            }`}
        >
          <HashLink
            smooth
            to={"/#features"}
            className="text-slate-200 hover:text-white"
            onClick={() => setMobileMenuOpen(false)}
          >
            How it works
          </HashLink>
          <HashLink
            smooth
            to={"/#testimonials"}
            className="text-slate-200 hover:text-white"
            onClick={() => setMobileMenuOpen(false)}
          >
            Testimonials
          </HashLink>
          <HashLink
            smooth
            to={"/#FAQs"}
            className="text-slate-200 hover:text-white"
            onClick={() => setMobileMenuOpen(false)}
          >
            FAQs
          </HashLink>
          {userInfo.user ? (
            <Button link="/dashboard" text="Get Started" />
          ) : (
            <div className="flex flex-row items-center justify-center md:justify-start gap-3">
              <Button link="/sign-in" text="Sign In" />
              <Button link="/sign-up" text="Sign Up" />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Navbar;
