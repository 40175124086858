import React from "react";
import brandLogo from "../../assets/TypeFast AI.png";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const footerLinks = [
  {
    "name": "Product", "links": [
      { "name": "Home", "link": "/" },
      { "name": "Chrom Extension", "link": "" },
      { "name": "Blog", "link": "/blog" },
      { "name": "Write a Review", "link": "https://senja.io/p/typefast-ai/r/feedback-form" },
    ]
  },
  {
    "name": "Company", "links": [
      { "name": "Contact", "link": "mailto:diyanshu0360@gmail.com" },
      { "name": "Terms", "link": "/terms" },
      { "name": "Privacy", "link": "/privacy" },
      { "name": "Refund", "link": "/refund" },
    ]
  },
  // {
  //   "name": "Use Cases", "links": [
  //     { "name": "For Blogs", "link": "" },
  //     { "name": "For Reddit", "link": "" },
  //     { "name": "For Twitter", "link": "" },
  //     { "name": "For LinkedIn", "link": "" },
  //     { "name": "For Medium", "link": "" },
  //     { "name": "For Product", "link": "" },
  //   ]
  // },
]

function Footer() {
  return (
    <footer className="bg-white w-full">
      <div className="mx-auto max-w-7xl p-5 space-y-8 py-10">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 md:w-1/3 space-y-4">
            <a href="https://typefastai.com" className="flex items-center">
              <Link
                to={"/"}
                className="flex gap-1 transition-all duration-100 items-center w-fit hover:scale-105 cursor-pointer"
              >
                <img src={brandLogo} alt="No Img" className="w-8 h-8 rounded-md" />
                <p className="text-2xl font-semibold text-primary">ypeFast AI</p>
              </Link>
            </a>
            <p>We're here to make your content creation journey even simpler. Our platform helps you create articles effortlessly, whether it's a general topic or product promotion. With a simple interface and optional filters, it's perfect for everyone</p>
            <div>
              <p className="text-sm text-gray-500">© {new Date().getFullYear()} <a href="https://typefastai.com" className="hover:underline">TypeFast AI</a>. All Rights Reserved.</p>
              {/* <p className="text-sm text-gray-500">A Product by 0360 Labs</p> */}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-5 sm:gap-10">
            {footerLinks.map((footerLink) => (
              <div className="space-y-2 sm:space-y-4">
                <h2 className="text-sm font-semibold text-gray-900 uppercase">{footerLink.name}</h2>
                <ul className="text-gray-600 space-y-1 sm:space-y-2">
                  {footerLink.links.map((link) => (
                    <li className="">
                      <a href={link.link} className="hover:underline">{link.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="text-center text-gray-500 text-sm sm:text-base">Created by <a href="https://twitter.com/diyanshu0360" target="_blank" className="hover:underline hover:text-primary transition-all font-semibold">Diyanshu Patel</a> and <a href="https://twitter.com/0xSatyajit" target="_blank" className="hover:underline hover:text-primary transition-all font-semibold">Satyajit Chaudhary</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
