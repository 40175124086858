import React from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaXmark } from "react-icons/fa6";

const data = [
    "Free Access",
    "Unlimited Article Generation",
    "Multiple Platform Coverage",
    "User-Friendly Interface",
    "Efficient History Management",
    "Continuous Innovation",
    "Dedicated Support",
]

function Compare() {
    const userInfo = useUser();
    const navigate = useNavigate();

    return (
        <div className="w-full bg-gray-50">
            <div id="Compare" className="max-w-7xl w-full bg-slate-50 m-auto px-5 py-10 flex flex-col gap-5 sm:gap-10 justify-center items-center">
                <div className="space-y-1 sm:space-y-2">
                    <p className="cursive text-primary text-lg text-center">Reason Behind Chossing Us!</p>
                    <p className="font-bold text-2xl md:text-5xl w-full text-center">
                        TypeFast AI vs Competitors
                    </p>
                </div>
                <div className="relative w-full overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                            <tr>
                                <th scope="col" className="px-6 py-3">

                                </th>
                                <th scope="col" className="px-6 py-3 w-1/6">
                                    TypeFast AI
                                </th>
                                <th scope="col" className="px-6 py-3 w-1/6">
                                    Competitors
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr className="bg-white">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                        {item}
                                    </th>
                                    <td className="px-6 py-4">
                                        <FaCheck color="green" size={20} />
                                    </td>
                                    <td className="px-6 py-4">
                                        <FaXmark color="red" size={20} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Compare;
