import React, { useState } from "react";
import { PiConfettiBold } from "react-icons/pi";
import axios from "axios";
import loadingImg from "../assets/Loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useUser } from "@clerk/clerk-react";
import Confetti from "./Confetti";
import toast from "react-hot-toast";
import PromptResult from "./PromptResult";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
const BACKEND_URL = process.env.REACT_APP_BACKEND_HOSTED_URL;

function RegenerateArticles() {
  const location = useLocation();
  const recievedSavedArticleData = location.state || null;
  const [promptDetails, setPromptDetails] = useState({
    productName: recievedSavedArticleData?.promptInput.productName || "",
    productCategory:
      recievedSavedArticleData?.promptInput.productCategory || "",
    productDescription:
      recievedSavedArticleData?.promptInput.productDescription || "",
    productReference:
      recievedSavedArticleData?.promptInput.productReference || "",
    productInstruction:
      recievedSavedArticleData?.promptInput.productInstruction || "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(false);
  const [promptResult, setPromptResult] = useState(
    recievedSavedArticleData?.promptResult || ""
  );

  const [fromHistory, setFromHistory] = useState(
    recievedSavedArticleData?.platform ? true : false
  );

  const userInfo = useUser();

  const handlePromptDetails = (itemValue, e) => {
    let nameData, valueData, typeData, checkedData;
    if (e) {
      const { name } = e;
      const { value } = itemValue;
      nameData = name;
      valueData = value;
    } else {
      const { name, value, type, checked } = itemValue.target;
      nameData = name;
      valueData = value;
      typeData = type;
      checkedData = checked;
    }

    setPromptDetails((prevInputs) => ({
      ...prevInputs,
      [nameData]: typeData === "checkbox" ? checkedData : valueData,
    }));
  };

  const handleSelectType = () => {
    setSelectedType(!selectedType);
    if (selectedType === true) {
      promptDetails.productName = "";
      promptDetails.productCategory = "";
      promptDetails.productDescription = "";
    }
  };

  const submitGenerateContent = async (e) => {
    e.preventDefault();
    try {
      if (promptResult !== null) {
        setPromptResult(null);
      }
      setLoading(true);
      const response = await axios.post(
        `${BACKEND_URL}/api/contents/regenerate`,
        {
          promptDetails,
          userEmail: userInfo.user.emailAddresses[0].emailAddress,
        }
      );

      if (response.data.completion) {
        setPromptResult(response.data.completion);
      } else {
        toast.error("Something went wrong! Please Try Again");
      }
      setLoading(false);
      setFromHistory(false);
      ReactGA.event({
        category: "Button Click",
        action: "Regenerate Button Clicked",
        label: "Regenerate Section",
      });
    } catch (error) {
      toast.error("Something went wrong! Please Try Again");
      setLoading(false);
    }
  };

  const handleReset = () => {
    setPromptDetails({
      productName: "",
      productCategory: "",
      productDescription: "",
      productReference: "",
      productInstruction: "",
    });
  };

  return (
    <div className="sm:pb-16 md:p-6 md:bg-slate-50 flex-1  min-w-0">
      <div className="bg-white md:border rounded-md p-4 sm:p-6 flex flex-col justify-center gap-3">
        <p className="font-semibold text-[15px]">Regenerate Article</p>
        <form
          className="flex flex-col justify-center gap-3 w-full md:w-11/12 lg:w-9/12 "
          onSubmit={submitGenerateContent}
        >
          <div className="form-check flex gap-3">
            <p className="font-semibold text-[15px]">Generate for a Product</p>
            <input
              id={`toggle-0`}
              onChange={() => handleSelectType()}
              type="checkbox"
              className={`toggle border-[#6035e0] ${
                selectedType
                  ? "bg-white hover:bg-[#f3f4f6] [--tglbg:#6035e0] hover:[--tglbg:#6035e0]"
                  : "bg-[#6035e0] hover:bg-[#6035e0] hover:[--tglbg:#f3f4f6]"
              }`}
            />
          </div>

          {selectedType === true && (
            <div className="flex flex-col gap-3">
              <p className="font-semibold text-[15px]">Product Details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <input
                  type="text"
                  placeholder="Enter Product Name:"
                  name="productName"
                  onChange={handlePromptDetails}
                  value={promptDetails.productName}
                  className="border-2 p-[6px] ps-3 pe-3 rounded-md col-span-1 focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200"
                  required
                />
                <input
                  type="text"
                  placeholder="Product's Category"
                  name="productCategory"
                  value={promptDetails.productCategory}
                  onChange={handlePromptDetails}
                  className="border-2 p-[6px] ps-3 pe-3 rounded-md col-span-1 focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200"
                  required
                />
              </div>
              <div className="flex flex-col gap-3">
                <textarea
                  className="border-2 p-[6px] ps-3 pe-3 rounded-md col-span-1 focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200"
                  name="productDescription"
                  onChange={handlePromptDetails}
                  value={promptDetails.productDescription}
                  cols="70"
                  rows="3"
                  placeholder="Enter Product Description"
                  required
                />
              </div>
            </div>
          )}
          <div className="space-y-2">
            <p className="font-semibold text-[15px]">Content Details</p>
            <textarea
              className="border-2 p-[6px] ps-3 pe-3 rounded-md w-full focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200"
              name="productReference"
              onChange={handlePromptDetails}
              value={promptDetails.productReference}
              cols="70"
              rows="3"
              placeholder="Enter the reference text"
              required
            />
            <textarea
              className="border-2 p-[6px] ps-3 pe-3 rounded-md w-full focus:border-[#6035e0] hover:border-gray-300 outline-none transition-all duration-200"
              name="productInstruction"
              onChange={handlePromptDetails}
              value={promptDetails.productInstruction}
              cols="70"
              rows="3"
              placeholder="Enter some optional instruction"
            />
          </div>
          <div className="flex flex-row items-center flex-wrap gap-3">
            <button className="border h-10 w-full sm:w-48 shadow-sm sm:max-w-[220px] hover:scale-105 hover:shadow-md transition-all duration-200 p-1 ps-4 pe-4 rounded-md flex justify-center gap-1 flex-row items-center bg-[#6035e0]">
              <PiConfettiBold className="text-white w-5 h-5" />
              <p className="text-white text-[15px] font-semibold">
                Click to Generate
              </p>
            </button>
            <div
              className={`order-3 sm:order-2 shadow-sm h-10 justify-center md:hover:scale-105 active:scale-105 md:active:scale-100 hover:shadow-md transition-all duration-200 px-2 lg:px-4 py-2 rounded-md flex gap-3 flex-row items-center w-full sm:w-36 sm:max-w-[220px] bg-[#6035e0] ${
                loading ? "cursor-not-allowed" : "cursor-pointer "
              }`}
              onClick={() => (loading ? null : handleReset())}
            >
              <p className="text-white text-[15px] font-semibold">Reset</p>
            </div>
          </div>
        </form>
      </div>
      {loading ? (
        <Player
          src={loadingImg}
          className="player w-12 scale-150"
          autoplay
          loop
        />
      ) : (
        promptResult && (
          <div className="relative">
            <Confetti />
            <PromptResult
              promptData={promptResult}
              promptDetails={promptDetails}
              platform={"Regenerate"}
              fromHistory={fromHistory}
            />
          </div>
        )
      )}
    </div>
  );
}

export default RegenerateArticles;
