import { SignIn, SignUp, SignedIn, SignedOut } from "@clerk/clerk-react";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Refund from "./pages/Refund";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/dashboard"
            exact
            element={
              <>
                <SignedIn>
                  <main className="w-screen h-screen">
                    <Dashboard />
                  </main>
                </SignedIn>
                <SignedOut>
                  <Navigate to={"/sign-in"} />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/dashboard/history"
            exact
            element={
              <>
                <SignedIn>
                  <main className="w-screen h-screen">
                    <Dashboard />
                  </main>
                </SignedIn>
                <SignedOut>
                  <Navigate to={"/sign-in"} />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/dashboard/regenerate"
            exact
            element={
              <>
                <SignedIn>
                  <main className="w-screen h-screen">
                    <Dashboard />
                  </main>
                </SignedIn>
                <SignedOut>
                  <Navigate to={"/sign-in"} />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/dashboard/upgrade-to-pro"
            exact
            element={
              <>
                <SignedIn>
                  <main className="w-screen h-screen">
                    <Dashboard />
                  </main>
                </SignedIn>
                <SignedOut>
                  <Navigate to={"/sign-in"} />
                </SignedOut>
              </>
            }
          />
          <Route
            path="/sign-in"
            element={
              <main className="w-screen h-screen flex flex-row justify-center items-center">
                <SignIn signUpUrl="/sign-up" redirectUrl={"/dashboard"} />
              </main>
            }
          />
          <Route
            path="/sign-up"
            element={
              <main className="w-screen h-screen flex flex-row justify-center items-center">
                <SignUp signInUrl="/sign-in" redirectUrl={"/dashboard"} />
              </main>
            }
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
