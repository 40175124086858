import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { FaCheck } from "react-icons/fa6";
import YoutubeEmbed from "../YoutubeEmbed";

function Hero() {
  const userInfo = useUser();
  const navigate = useNavigate();

  return (
    <div className="w-full bg-primary">
      <div className="max-w-7xl w-full m-auto p-4 pt-8 sm:p-8 sm:py-16 flex flex-col gap-5 justify-center text-center items-center text-white bg-primary">
        <p className="text-3xl md:text-6xl font-bold w-full md:w-[60%]">
          Write. Click. Share.
        </p>
        <p className="w-full md:w-[60%] text-slate-200 text-base sm:text-lg">
          Easy writing, Big impact! Generate General and Product Articles with Ease for Reddit, Twitter, LinkedIn, Medium, and Blogs!

        </p>
        <button
          onClick={userInfo.user ? () => navigate("/dashboard") : () => navigate("/sign-up")}
          className="p-3 ps-5 pe-5 rounded-full font-semibold shadow-sm bg-white text-primary hover:scale-105 hover:shadow-md transition-all duration-100"
        >
          Generate Articles Now
        </button>
        <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
          <div className="flex items-center gap-1 text-sm"><FaCheck /> <span>100% Free to use</span></div>
          <div className="flex items-center gap-1 text-sm"><FaCheck /> <span>No Card/Payment Required</span></div>
          <div className="flex items-center gap-1 text-sm"><FaCheck /> <span>Unlimited Articles</span></div>
        </div>
        {/* <YoutubeEmbed embedId="S1gjSuHyY_8" className="mt-10" /> */}
        <img src="/dashboard.png" alt="Dashboard" className="border border-gray-400 rounded-lg mt-10 sm:mt-16" />
      </div>
    </div>
  );
}

export default Hero;
