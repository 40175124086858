export const contentInput={
  content:[
                {
                  value: "Reddit",
                  label: "Reddit",
                },
                {
                  value: "Twitter",
                  label: "Twitter",
                },
                {
                  value: "LinkedIn",
                  label: "LinkedIn",
                },
                {
                  value: "Medium",
                  label: "Medium",
                },
                {
                  value: "Blog",
                  label: "Blog",
                },
                {
                  value: "Product Description",
                  label: "Product Description",
                },
              ],
}
export const productInputs = {
  products:[
      {
        value: "Developer Tools",
        label: "Developer Tools",
      },
      {
        value: "Consulting Services",
        label: "Consulting Services",
      },
      {
        value: "Founder's Toolkit",
        label: "Founder's Toolkit",
      },
      {
        value: "Tech Innovations",
        label: "Tech Innovations",
      },
      {
        value: "Digital Marketing",
        label: "Digital Marketing",
      },
      {
        value: "Creativity & Design",
        label: "Creativity & Design",
      },
    
  ],
};
export const redditInputs = {
  keywords:[
    {
      value: "Digital Transformation",
      label: "Digital Transformation",
    },
    {
      value: "Innovative Solutions",
      label: "Innovative Solutions",
    },
    {
      value: "User-Friendly",
      label: "User-Friendly",
    },
    {
      value: "Cutting-Edge Technology",
      label: "Cutting-Edge Technology",
    },
    {
      value: "Seamless Integration",
      label: "Seamless Integration",
    },
    {
      value: "Cloud Computing",
      label: "Cloud Computing",
    },
    {
      value: "Artificial Intelligence",
      label: "Artificial Intelligence",
    },
    {
      value: "Machine Learning",
      label: "Machine Learning",
    },
    {
      value: "Cybersecurity",
      label: "Cybersecurity",
    },
    {
      value: "Data Privacy",
      label: "Data Privacy",
    },
    {
      value: "User Experience (UX)",
      label: "User Experience (UX)",
    },
    {
      value: "Scalability",
      label: "Scalability",
    },
    {
      value: "Automation",
      label: "Automation",
    },
    {
      value: "Mobile Optimization",
      label: "Mobile Optimization",
    },
    {
      value: "Cross-Platform Compatibility",
      label: "Cross-Platform Compatibility",
    },
    {
      value: "Advanced Analytics",
      label: "Advanced Analytics",
    },
    {
      value: "Virtual Reality (VR)",
      label: "Virtual Reality (VR)",
    },
    {
      value: "Augmented Reality (AR)",
      label: "Augmented Reality (AR)",
    },
    {
      value: "24/7 Support",
      label: "24/7 Support",
    },
    {
      value: "Customization Options",
      label: "Customization Options",
    },
    {
      value: "SaaS (Software as a Service)",
      label: "SaaS (Software as a Service)",
    },
    {
      value: "E-commerce Solutions",
      label: "E-commerce Solutions",
    },
    {
      value: "Time-Saving",
      label: "Time-Saving",
    },
    {
      value: "Cost-Efficient",
      label: "Cost-Efficient",
    },
    {
      value: "Productivity Enhancement",
      label: "Productivity Enhancement",
    },
    {
      value: "Real-time Insights",
      label: "Real-time Insights",
    },
    {
      value: "Social Media Integration",
      label: "Social Media Integration",
    },
    {
      value: "API (Application Programming Interface)",
      label: "API (Application Programming Interface)",
    },
    {
      value: "Data-driven Decisions",
      label: "Data-driven Decisions",
    },
    {
      value: "Future-Proof",
      label: "Future-Proof",
    },
  ],
  subRedditCategory: [
    { 
      value:"Programming & Development",
      label:"Programming & Development",
    },
    { 
      value:"Startups & Entrepreneurship",
      label:"Startups & Entrepreneurship",
    },
    { 
      value:"Product Management",
      label:"Product Management",
    },
    { 
      value:"Marketing & Promotion",
      label:"Marketing & Promotion",
    },
    { 
      value:"Tech News & Trends",
      label:"Tech News & Trends",
    },

    { 
      value:"Design & User Experience",
      label:"Design & User Experience",
    },
    { 
      value:"Freelancing & Solopreneurship",
      label:"Freelancing & Solopreneurship",
    },
    { 
      value:"Community Building",
      label:"Community Building",
    },
    { 
      value:"Coding Challenges & Competitions",
      label:"Coding Challenges & Competitions",
    },
    { 
      value:"Finance & Funding",
      label:"Finance & Funding",
    },
    { 
      value:"Remote Work & Tools",
      label:"Remote Work & Tools",
    },
    { 
      value:"Indie Game Development",
      label:"Indie Game Development",
    },
    { 
      value:"Others",
      label:"Others",
    },

  ],
  postType: [
    { 
      value:"Text",
      label:"Text",
    },
    { 
      value:"Text + Link",
      label:"Text + Link",
    },
  ],
  titleType: [
    { 
      value:"Question-Based",
      label:"Question-Based",
    },
    { 
      value:"Curiosity-Piquing",
      label:"Curiosity-Piquing",
    },
    { 
      value:"Listicle",
      label:"Listicle",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Announcement or Breaking News",
      label:"Announcement or Breaking News",
    },
    { 
      value:"Controversial or Thought-Provoking",
      label:"Controversial or Thought-Provoking",
    },
    { 
      value:"Storytelling",
      label:"Storytelling",
    },
    { 
      value:"Mysterious",
      label:"Mysterious",
    },
    { 
      value:"Emotionally Charged",
      label:"Emotionally Charged",
    },
    { 
      value:"Anniversary or Milestone",
      label:"Anniversary or Milestone",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};

export const twitterInputs = {
  callToAction:[
    { 
      value:"Discover the difference today!",
      label:"Discover the difference today!",
    },
    { 
      value:"Unlock exclusive features now.",
      label:"Unlock exclusive features now.",
    },
    { 
      value:"Experience innovation firsthand.",
      label:"Experience innovation firsthand.",
    },
    { 
      value:"Get started on your digital journey.",
      label:"Get started on your digital journey.",
    },
    { 
      value:"Claim your free trial.",
      label:"Claim your free trial.",
    },
    { 
      value:"Upgrade your digital experience.",
      label:"Upgrade your digital experience.",
    },
    { 
      value:"Join the revolution - try it now!",
      label:"Join the revolution - try it now!",
    },
    { 
      value:"Seize the opportunity, sign up!",
      label:"Seize the opportunity, sign up!",
    },
    { 
      value:"Transform your digital world today.",
      label:"Transform your digital world today.",
    },
    { 
      value:"Don't miss out - sign up now!",
      label:"Don't miss out - sign up now!",
    },
    { 
      value:"Start your free trial instantly.",
      label:"Start your free trial instantly.",
    },
    { 
      value:"Upgrade to premium benefits.",
      label:"Upgrade to premium benefits.",
    },
    { 
      value:"Optimize your digital life now.",
      label:"Optimize your digital life now.",
    },
    { 
      value:"Maximize your productivity, sign up!",
      label:"Maximize your productivity, sign up!",
    },
    { 
      value:"Take the first step - sign up today.",
      label:"Take the first step - sign up today.",
    },
    { 
      value:"Empower your digital presence.",
      label:"Empower your digital presence.",
    },
    { 
      value:"Unleash the power of [product] now.",
      label:"Unleash the power of [product] now.",
    },
    { 
      value:"Elevate your digital experience.",
      label:"Elevate your digital experience.",
    },
    { 
      value:"Join the community, get started!",
      label:"Join the community, get started!",
    },
    { 
      value:"Revolutionize your workflow - sign up!",
      label:"Revolutionize your workflow - sign up!",
    },
  ],
  targetAudience:[
    { 
      value:"Tech Enthusiasts",
      label:"Tech Enthusiasts",
    },
    { 
      value:"Entrepreneurs and Startups",
      label:"Entrepreneurs and Startups",
    },
    { 
      value:"Small Business Owners",
      label:"Small Business Owners",
    },
    { 
      value:"Developers and Programmers",
      label:"Developers and Programmers",
    },
    { 
      value:"Digital Marketers",
      label:"Digital Marketers",
    },
    { 
      value:"E-commerce Professionals",
      label:"E-commerce Professionals",
    },
    { 
      value:"Content Creators",
      label:"Content Creators",
    },
    { 
      value:"Designers and Creatives",
      label:"Designers and Creatives",
    },
    { 
      value:"Remote Workers and Freelancers",
      label:"Remote Workers and Freelancers",
    },
    { 
      value:"Students and Educators",
      label:"Students and Educators",
    },
    { 
      value:"Gamers",
      label:"Gamers",
    },
    { 
      value:"Health and Fitness Enthusiasts",
      label:"Health and Fitness Enthusiasts",
    },
    { 
      value:"Data Scientists and Analysts",
      label:"Data Scientists and Analysts",
    },
    { 
      value:"Financial Professionals",
      label:"Financial Professionals",
    },
    { 
      value:"Travel Enthusiasts",
      label:"Travel Enthusiasts",
    },
    { 
      value:"Social Media Managers",
      label:"Social Media Managers",
    },
    { 
      value:"Nonprofit Organizations",
      label:"Nonprofit Organizations",
    },
    { 
      value:"Cybersecurity Professionals",
      label:"Cybersecurity Professionals",
    },
    { 
      value:"AI and Machine Learning Enthusiasts",
      label:"AI and Machine Learning Enthusiasts",
    },
    { 
      value:"Product Managers",
      label:"Product Managers",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};


export const linkedInInputs = {
  callToAction:[
    { 
      value:"Discover the difference today!",
      label:"Discover the difference today!",
    },
    { 
      value:"Unlock exclusive features now.",
      label:"Unlock exclusive features now.",
    },
    { 
      value:"Experience innovation firsthand.",
      label:"Experience innovation firsthand.",
    },
    { 
      value:"Get started on your digital journey.",
      label:"Get started on your digital journey.",
    },
    { 
      value:"Claim your free trial.",
      label:"Claim your free trial.",
    },
    { 
      value:"Upgrade your digital experience.",
      label:"Upgrade your digital experience.",
    },
    { 
      value:"Join the revolution - try it now!",
      label:"Join the revolution - try it now!",
    },
    { 
      value:"Seize the opportunity, sign up!",
      label:"Seize the opportunity, sign up!",
    },
    { 
      value:"Transform your digital world today.",
      label:"Transform your digital world today.",
    },
    { 
      value:"Don't miss out - sign up now!",
      label:"Don't miss out - sign up now!",
    },
    { 
      value:"Start your free trial instantly.",
      label:"Start your free trial instantly.",
    },
    { 
      value:"Upgrade to premium benefits.",
      label:"Upgrade to premium benefits.",
    },
    { 
      value:"Optimize your digital life now.",
      label:"Optimize your digital life now.",
    },
    { 
      value:"Maximize your productivity, sign up!",
      label:"Maximize your productivity, sign up!",
    },
    { 
      value:"Take the first step - sign up today.",
      label:"Take the first step - sign up today.",
    },
    { 
      value:"Empower your digital presence.",
      label:"Empower your digital presence.",
    },
    { 
      value:"Unleash the power of [product] now.",
      label:"Unleash the power of [product] now.",
    },
    { 
      value:"Elevate your digital experience.",
      label:"Elevate your digital experience.",
    },
    { 
      value:"Join the community, get started!",
      label:"Join the community, get started!",
    },
    { 
      value:"Revolutionize your workflow - sign up!",
      label:"Revolutionize your workflow - sign up!",
    },
  ],
  targetAudience:[
    { 
      value:"Tech Enthusiasts",
      label:"Tech Enthusiasts",
    },
    { 
      value:"Entrepreneurs and Startups",
      label:"Entrepreneurs and Startups",
    },
    { 
      value:"Small Business Owners",
      label:"Small Business Owners",
    },
    { 
      value:"Developers and Programmers",
      label:"Developers and Programmers",
    },
    { 
      value:"Digital Marketers",
      label:"Digital Marketers",
    },
    { 
      value:"E-commerce Professionals",
      label:"E-commerce Professionals",
    },
    { 
      value:"Content Creators",
      label:"Content Creators",
    },
    { 
      value:"Designers and Creatives",
      label:"Designers and Creatives",
    },
    { 
      value:"Remote Workers and Freelancers",
      label:"Remote Workers and Freelancers",
    },
    { 
      value:"Students and Educators",
      label:"Students and Educators",
    },
    { 
      value:"Gamers",
      label:"Gamers",
    },
    { 
      value:"Health and Fitness Enthusiasts",
      label:"Health and Fitness Enthusiasts",
    },
    { 
      value:"Data Scientists and Analysts",
      label:"Data Scientists and Analysts",
    },
    { 
      value:"Financial Professionals",
      label:"Financial Professionals",
    },
    { 
      value:"Travel Enthusiasts",
      label:"Travel Enthusiasts",
    },
    { 
      value:"Social Media Managers",
      label:"Social Media Managers",
    },
    { 
      value:"Nonprofit Organizations",
      label:"Nonprofit Organizations",
    },
    { 
      value:"Cybersecurity Professionals",
      label:"Cybersecurity Professionals",
    },
    { 
      value:"AI and Machine Learning Enthusiasts",
      label:"AI and Machine Learning Enthusiasts",
    },
    { 
      value:"Product Managers",
      label:"Product Managers",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};

export const blogsInputs = {
  callToAction:[
    { 
      value:"Discover the difference today!",
      label:"Discover the difference today!",
    },
    { 
      value:"Unlock exclusive features now.",
      label:"Unlock exclusive features now.",
    },
    { 
      value:"Experience innovation firsthand.",
      label:"Experience innovation firsthand.",
    },
    { 
      value:"Get started on your digital journey.",
      label:"Get started on your digital journey.",
    },
    { 
      value:"Claim your free trial.",
      label:"Claim your free trial.",
    },
    { 
      value:"Upgrade your digital experience.",
      label:"Upgrade your digital experience.",
    },
    { 
      value:"Join the revolution - try it now!",
      label:"Join the revolution - try it now!",
    },
    { 
      value:"Seize the opportunity, sign up!",
      label:"Seize the opportunity, sign up!",
    },
    { 
      value:"Transform your digital world today.",
      label:"Transform your digital world today.",
    },
    { 
      value:"Don't miss out - sign up now!",
      label:"Don't miss out - sign up now!",
    },
    { 
      value:"Start your free trial instantly.",
      label:"Start your free trial instantly.",
    },
    { 
      value:"Upgrade to premium benefits.",
      label:"Upgrade to premium benefits.",
    },
    { 
      value:"Optimize your digital life now.",
      label:"Optimize your digital life now.",
    },
    { 
      value:"Maximize your productivity, sign up!",
      label:"Maximize your productivity, sign up!",
    },
    { 
      value:"Take the first step - sign up today.",
      label:"Take the first step - sign up today.",
    },
    { 
      value:"Empower your digital presence.",
      label:"Empower your digital presence.",
    },
    { 
      value:"Unleash the power of [product] now.",
      label:"Unleash the power of [product] now.",
    },
    { 
      value:"Elevate your digital experience.",
      label:"Elevate your digital experience.",
    },
    { 
      value:"Join the community, get started!",
      label:"Join the community, get started!",
    },
    { 
      value:"Revolutionize your workflow - sign up!",
      label:"Revolutionize your workflow - sign up!",
    },
  ],
  targetAudience:[
    { 
      value:"Tech Enthusiasts",
      label:"Tech Enthusiasts",
    },
    { 
      value:"Entrepreneurs and Startups",
      label:"Entrepreneurs and Startups",
    },
    { 
      value:"Small Business Owners",
      label:"Small Business Owners",
    },
    { 
      value:"Developers and Programmers",
      label:"Developers and Programmers",
    },
    { 
      value:"Digital Marketers",
      label:"Digital Marketers",
    },
    { 
      value:"E-commerce Professionals",
      label:"E-commerce Professionals",
    },
    { 
      value:"Content Creators",
      label:"Content Creators",
    },
    { 
      value:"Designers and Creatives",
      label:"Designers and Creatives",
    },
    { 
      value:"Remote Workers and Freelancers",
      label:"Remote Workers and Freelancers",
    },
    { 
      value:"Students and Educators",
      label:"Students and Educators",
    },
    { 
      value:"Gamers",
      label:"Gamers",
    },
    { 
      value:"Health and Fitness Enthusiasts",
      label:"Health and Fitness Enthusiasts",
    },
    { 
      value:"Data Scientists and Analysts",
      label:"Data Scientists and Analysts",
    },
    { 
      value:"Financial Professionals",
      label:"Financial Professionals",
    },
    { 
      value:"Travel Enthusiasts",
      label:"Travel Enthusiasts",
    },
    { 
      value:"Social Media Managers",
      label:"Social Media Managers",
    },
    { 
      value:"Nonprofit Organizations",
      label:"Nonprofit Organizations",
    },
    { 
      value:"Cybersecurity Professionals",
      label:"Cybersecurity Professionals",
    },
    { 
      value:"AI and Machine Learning Enthusiasts",
      label:"AI and Machine Learning Enthusiasts",
    },
    { 
      value:"Product Managers",
      label:"Product Managers",
    },
  ],
  keywords:[
    {
      value: "Digital Transformation",
      label: "Digital Transformation",
    },
    {
      value: "Innovative Solutions",
      label: "Innovative Solutions",
    },
    {
      value: "User-Friendly",
      label: "User-Friendly",
    },
    {
      value: "Cutting-Edge Technology",
      label: "Cutting-Edge Technology",
    },
    {
      value: "Seamless Integration",
      label: "Seamless Integration",
    },
    {
      value: "Cloud Computing",
      label: "Cloud Computing",
    },
    {
      value: "Artificial Intelligence",
      label: "Artificial Intelligence",
    },
    {
      value: "Machine Learning",
      label: "Machine Learning",
    },
    {
      value: "Cybersecurity",
      label: "Cybersecurity",
    },
    {
      value: "Data Privacy",
      label: "Data Privacy",
    },
    {
      value: "User Experience (UX)",
      label: "User Experience (UX)",
    },
    {
      value: "Scalability",
      label: "Scalability",
    },
    {
      value: "Automation",
      label: "Automation",
    },
    {
      value: "Mobile Optimization",
      label: "Mobile Optimization",
    },
    {
      value: "Cross-Platform Compatibility",
      label: "Cross-Platform Compatibility",
    },
    {
      value: "Advanced Analytics",
      label: "Advanced Analytics",
    },
    {
      value: "Virtual Reality (VR)",
      label: "Virtual Reality (VR)",
    },
    {
      value: "Augmented Reality (AR)",
      label: "Augmented Reality (AR)",
    },
    {
      value: "24/7 Support",
      label: "24/7 Support",
    },
    {
      value: "Customization Options",
      label: "Customization Options",
    },
    {
      value: "SaaS (Software as a Service)",
      label: "SaaS (Software as a Service)",
    },
    {
      value: "E-commerce Solutions",
      label: "E-commerce Solutions",
    },
    {
      value: "Time-Saving",
      label: "Time-Saving",
    },
    {
      value: "Cost-Efficient",
      label: "Cost-Efficient",
    },
    {
      value: "Productivity Enhancement",
      label: "Productivity Enhancement",
    },
    {
      value: "Real-time Insights",
      label: "Real-time Insights",
    },
    {
      value: "Social Media Integration",
      label: "Social Media Integration",
    },
    {
      value: "API (Application Programming Interface)",
      label: "API (Application Programming Interface)",
    },
    {
      value: "Data-driven Decisions",
      label: "Data-driven Decisions",
    },
    {
      value: "Future-Proof",
      label: "Future-Proof",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};

export const mediumInputs = {
  callToAction:[
    { 
      value:"Discover the difference today!",
      label:"Discover the difference today!",
    },
    { 
      value:"Unlock exclusive features now.",
      label:"Unlock exclusive features now.",
    },
    { 
      value:"Experience innovation firsthand.",
      label:"Experience innovation firsthand.",
    },
    { 
      value:"Get started on your digital journey.",
      label:"Get started on your digital journey.",
    },
    { 
      value:"Claim your free trial.",
      label:"Claim your free trial.",
    },
    { 
      value:"Upgrade your digital experience.",
      label:"Upgrade your digital experience.",
    },
    { 
      value:"Join the revolution - try it now!",
      label:"Join the revolution - try it now!",
    },
    { 
      value:"Seize the opportunity, sign up!",
      label:"Seize the opportunity, sign up!",
    },
    { 
      value:"Transform your digital world today.",
      label:"Transform your digital world today.",
    },
    { 
      value:"Don't miss out - sign up now!",
      label:"Don't miss out - sign up now!",
    },
    { 
      value:"Start your free trial instantly.",
      label:"Start your free trial instantly.",
    },
    { 
      value:"Upgrade to premium benefits.",
      label:"Upgrade to premium benefits.",
    },
    { 
      value:"Optimize your digital life now.",
      label:"Optimize your digital life now.",
    },
    { 
      value:"Maximize your productivity, sign up!",
      label:"Maximize your productivity, sign up!",
    },
    { 
      value:"Take the first step - sign up today.",
      label:"Take the first step - sign up today.",
    },
    { 
      value:"Empower your digital presence.",
      label:"Empower your digital presence.",
    },
    { 
      value:"Unleash the power of [product] now.",
      label:"Unleash the power of [product] now.",
    },
    { 
      value:"Elevate your digital experience.",
      label:"Elevate your digital experience.",
    },
    { 
      value:"Join the community, get started!",
      label:"Join the community, get started!",
    },
    { 
      value:"Revolutionize your workflow - sign up!",
      label:"Revolutionize your workflow - sign up!",
    },
  ],
  targetAudience:[
    { 
      value:"Tech Enthusiasts",
      label:"Tech Enthusiasts",
    },
    { 
      value:"Entrepreneurs and Startups",
      label:"Entrepreneurs and Startups",
    },
    { 
      value:"Small Business Owners",
      label:"Small Business Owners",
    },
    { 
      value:"Developers and Programmers",
      label:"Developers and Programmers",
    },
    { 
      value:"Digital Marketers",
      label:"Digital Marketers",
    },
    { 
      value:"E-commerce Professionals",
      label:"E-commerce Professionals",
    },
    { 
      value:"Content Creators",
      label:"Content Creators",
    },
    { 
      value:"Designers and Creatives",
      label:"Designers and Creatives",
    },
    { 
      value:"Remote Workers and Freelancers",
      label:"Remote Workers and Freelancers",
    },
    { 
      value:"Students and Educators",
      label:"Students and Educators",
    },
    { 
      value:"Gamers",
      label:"Gamers",
    },
    { 
      value:"Health and Fitness Enthusiasts",
      label:"Health and Fitness Enthusiasts",
    },
    { 
      value:"Data Scientists and Analysts",
      label:"Data Scientists and Analysts",
    },
    { 
      value:"Financial Professionals",
      label:"Financial Professionals",
    },
    { 
      value:"Travel Enthusiasts",
      label:"Travel Enthusiasts",
    },
    { 
      value:"Social Media Managers",
      label:"Social Media Managers",
    },
    { 
      value:"Nonprofit Organizations",
      label:"Nonprofit Organizations",
    },
    { 
      value:"Cybersecurity Professionals",
      label:"Cybersecurity Professionals",
    },
    { 
      value:"AI and Machine Learning Enthusiasts",
      label:"AI and Machine Learning Enthusiasts",
    },
    { 
      value:"Product Managers",
      label:"Product Managers",
    },
  ],
  keywords:[
    {
      value: "Digital Transformation",
      label: "Digital Transformation",
    },
    {
      value: "Innovative Solutions",
      label: "Innovative Solutions",
    },
    {
      value: "User-Friendly",
      label: "User-Friendly",
    },
    {
      value: "Cutting-Edge Technology",
      label: "Cutting-Edge Technology",
    },
    {
      value: "Seamless Integration",
      label: "Seamless Integration",
    },
    {
      value: "Cloud Computing",
      label: "Cloud Computing",
    },
    {
      value: "Artificial Intelligence",
      label: "Artificial Intelligence",
    },
    {
      value: "Machine Learning",
      label: "Machine Learning",
    },
    {
      value: "Cybersecurity",
      label: "Cybersecurity",
    },
    {
      value: "Data Privacy",
      label: "Data Privacy",
    },
    {
      value: "User Experience (UX)",
      label: "User Experience (UX)",
    },
    {
      value: "Scalability",
      label: "Scalability",
    },
    {
      value: "Automation",
      label: "Automation",
    },
    {
      value: "Mobile Optimization",
      label: "Mobile Optimization",
    },
    {
      value: "Cross-Platform Compatibility",
      label: "Cross-Platform Compatibility",
    },
    {
      value: "Advanced Analytics",
      label: "Advanced Analytics",
    },
    {
      value: "Virtual Reality (VR)",
      label: "Virtual Reality (VR)",
    },
    {
      value: "Augmented Reality (AR)",
      label: "Augmented Reality (AR)",
    },
    {
      value: "24/7 Support",
      label: "24/7 Support",
    },
    {
      value: "Customization Options",
      label: "Customization Options",
    },
    {
      value: "SaaS (Software as a Service)",
      label: "SaaS (Software as a Service)",
    },
    {
      value: "E-commerce Solutions",
      label: "E-commerce Solutions",
    },
    {
      value: "Time-Saving",
      label: "Time-Saving",
    },
    {
      value: "Cost-Efficient",
      label: "Cost-Efficient",
    },
    {
      value: "Productivity Enhancement",
      label: "Productivity Enhancement",
    },
    {
      value: "Real-time Insights",
      label: "Real-time Insights",
    },
    {
      value: "Social Media Integration",
      label: "Social Media Integration",
    },
    {
      value: "API (Application Programming Interface)",
      label: "API (Application Programming Interface)",
    },
    {
      value: "Data-driven Decisions",
      label: "Data-driven Decisions",
    },
    {
      value: "Future-Proof",
      label: "Future-Proof",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};

export const productDescriptionInputs = {
  useCase:[
    { 
      value:"E-commerce Optimization",
      label:"E-commerce Optimization",
    },
    { 
      value:"Digital Marketing Automation",
      label:"Digital Marketing Automation",
    },
    { 
      value:"Cybersecurity Solutions",
      label:"Cybersecurity Solutions",
    },
    { 
      value:"Data Analytics and Business Intelligence",
      label:"Data Analytics and Business Intelligence",
    },
    { 
      value:"Cloud Computing Services",
      label:"Cloud Computing Services",
    },
    { 
      value:"Mobile App Development",
      label:"Mobile App Development",
    },
    { 
      value:"Website Design and Development",
      label:"Website Design and Development",
    },
    { 
      value:"Customer Relationship Management (CRM)",
      label:"Customer Relationship Management (CRM)",
    },
    { 
      value:"Project Management Software",
      label:"Project Management Software",
    },
    { 
      value:"Virtual Reality (VR) Applications",
      label:"Virtual Reality (VR) Applications",
    },
    { 
      value:"Augmented Reality (AR) Solutions",
      label:"Augmented Reality (AR) Solutions",
    },
    { 
      value:"E-learning Platforms",
      label:"E-learning Platforms",
    },
    { 
      value:"Social Media Management",
      label:"Social Media Management",
    },
    { 
      value:"Content Creation and Marketing",
      label:"Content Creation and Marketing",
    },
    { 
      value:"Online Collaboration Tools",
      label:"Online Collaboration Tools",
    },
    { 
      value:"Health and Wellness Apps",
      label:"Health and Wellness Apps",
    },
    { 
      value:"Financial Management Software",
      label:"Financial Management Software",
    },
    { 
      value:"E-commerce Payment Gateways",
      label:"E-commerce Payment Gateways",
    },
    { 
      value:"Artificial Intelligence (AI) Applications",
      label:"Artificial Intelligence (AI) Applications",
    },
    { 
      value:"Internet of Things (IoT) Devices",
      label:"Internet of Things (IoT) Devices",
    },
  ],
  callToAction:[
    { 
      value:"Discover the difference today!",
      label:"Discover the difference today!",
    },
    { 
      value:"Unlock exclusive features now.",
      label:"Unlock exclusive features now.",
    },
    { 
      value:"Experience innovation firsthand.",
      label:"Experience innovation firsthand.",
    },
    { 
      value:"Get started on your digital journey.",
      label:"Get started on your digital journey.",
    },
    { 
      value:"Claim your free trial.",
      label:"Claim your free trial.",
    },
    { 
      value:"Upgrade your digital experience.",
      label:"Upgrade your digital experience.",
    },
    { 
      value:"Join the revolution - try it now!",
      label:"Join the revolution - try it now!",
    },
    { 
      value:"Seize the opportunity, sign up!",
      label:"Seize the opportunity, sign up!",
    },
    { 
      value:"Transform your digital world today.",
      label:"Transform your digital world today.",
    },
    { 
      value:"Don't miss out - sign up now!",
      label:"Don't miss out - sign up now!",
    },
    { 
      value:"Start your free trial instantly.",
      label:"Start your free trial instantly.",
    },
    { 
      value:"Upgrade to premium benefits.",
      label:"Upgrade to premium benefits.",
    },
    { 
      value:"Optimize your digital life now.",
      label:"Optimize your digital life now.",
    },
    { 
      value:"Maximize your productivity, sign up!",
      label:"Maximize your productivity, sign up!",
    },
    { 
      value:"Take the first step - sign up today.",
      label:"Take the first step - sign up today.",
    },
    { 
      value:"Empower your digital presence.",
      label:"Empower your digital presence.",
    },
    { 
      value:"Unleash the power of [product] now.",
      label:"Unleash the power of [product] now.",
    },
    { 
      value:"Elevate your digital experience.",
      label:"Elevate your digital experience.",
    },
    { 
      value:"Join the community, get started!",
      label:"Join the community, get started!",
    },
    { 
      value:"Revolutionize your workflow - sign up!",
      label:"Revolutionize your workflow - sign up!",
    },
  ],
  targetAudience:[
    { 
      value:"Tech Enthusiasts",
      label:"Tech Enthusiasts",
    },
    { 
      value:"Entrepreneurs and Startups",
      label:"Entrepreneurs and Startups",
    },
    { 
      value:"Small Business Owners",
      label:"Small Business Owners",
    },
    { 
      value:"Developers and Programmers",
      label:"Developers and Programmers",
    },
    { 
      value:"Digital Marketers",
      label:"Digital Marketers",
    },
    { 
      value:"E-commerce Professionals",
      label:"E-commerce Professionals",
    },
    { 
      value:"Content Creators",
      label:"Content Creators",
    },
    { 
      value:"Designers and Creatives",
      label:"Designers and Creatives",
    },
    { 
      value:"Remote Workers and Freelancers",
      label:"Remote Workers and Freelancers",
    },
    { 
      value:"Students and Educators",
      label:"Students and Educators",
    },
    { 
      value:"Gamers",
      label:"Gamers",
    },
    { 
      value:"Health and Fitness Enthusiasts",
      label:"Health and Fitness Enthusiasts",
    },
    { 
      value:"Data Scientists and Analysts",
      label:"Data Scientists and Analysts",
    },
    { 
      value:"Financial Professionals",
      label:"Financial Professionals",
    },
    { 
      value:"Travel Enthusiasts",
      label:"Travel Enthusiasts",
    },
    { 
      value:"Social Media Managers",
      label:"Social Media Managers",
    },
    { 
      value:"Nonprofit Organizations",
      label:"Nonprofit Organizations",
    },
    { 
      value:"Cybersecurity Professionals",
      label:"Cybersecurity Professionals",
    },
    { 
      value:"AI and Machine Learning Enthusiasts",
      label:"AI and Machine Learning Enthusiasts",
    },
    { 
      value:"Product Managers",
      label:"Product Managers",
    },
  ],
  engagementGoals: [
    { 
      value:"Generate Discussions",
      label:"Generate Discussions",
    },
    { 
      value:"Get Upvotes",
      label:"Get Upvotes",
    },
    { 
      value:"Promote a Call-to-Action (CTA)",
      label:"Promote a Call-to-Action (CTA)",
    },
    { 
      value:"Increase Visibility",
      label:"Increase Visibility",
    },
    { 
      value:"Educate the Community",
      label:"Educate the Community",
    },
    { 
      value:"Ask for Advice or Recommendations",
      label:"Ask for Advice or Recommendations",
    },
    { 
      value:"Highlight Community Involvement",
      label:"Highlight Community Involvement",
    },
    { 
      value:"Celebrate Milestones",
      label:"Celebrate Milestones",
    },
    { 
      value:"Share Industry Insights",
      label:"Share Industry Insights",
    },
    { 
      value:"Entertain the Community",
      label:"Entertain the Community",
    },
    { 
      value:"Support a Cause",
      label:"Support a Cause",
    },
  ],
  tone: [
    { 
      value:"Conversational",
      label:"Conversational",
    },
    { 
      value:"Casual",
      label:"Casual",
    },
    { 
      value:"Formal",
      label:"Formal",
    },
    { 
      value:"Humorous",
      label:"Humorous",
    },
    { 
      value:"Authoritative",
      label:"Authoritative",
    },
    { 
      value:"Positive",
      label:"Positive",
    },
    { 
      value:"Neutral",
      label:"Neutral",
    },
    { 
      value:"Controversial",
      label:"Controversial",
    },
    { 
      value:"Educational",
      label:"Educational",
    },
    { 
      value:"Supportive",
      label:"Supportive",
    },
    { 
      value:"Ask Me Anything",
      label:"Ask Me Anything",
    },
  ],
};
