import React from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const Featurecard = (props) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
      <div className={`col-span-1 space-y-4 ${props.index % 2 === 0 ? 'order-1 md:order-2' : 'order-1'}`}>
        <div className="space-y-2">
          <p className="font-bold text-2xl sm:text-[28px]">{props.title}</p>
          <p className="text-base sm:text-lg">{props.description}</p>
        </div>
      </div>
      <div className={`col-span-1 border h-fit rounded-lg  ${props.index % 2 === 0 ? 'order-2 md:order-1' : 'order-2'}`}>
        <img src={props.img} alt={props.alt} className="w-full rounded-lg" />
      </div>
    </div>
  );
};

function Features() {
  const userInfo = useUser();
  const navigate = useNavigate();

  return (
    <div className="w-full bg-gray-50">
      <div id="features" className="max-w-7xl bg-slate-50 m-auto px-5 py-10 flex flex-col gap-5 sm:gap-10  justify-center items-center">
        <div className="space-y-1 sm:space-y-2">
          <p className="cursive text-primary text-lg text-center">Easy Never Before</p>
          <p className="font-bold text-3xl md:text-5xl w-full text-center">
            How it Works
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-10 md:gap-20">
          {[
            {
              title: "Simple Words, Strong Impact",
              description: `For general articles, choose a topic, pick a title, and tweak with optional filters. Same goes for product articles – provide details, choose a topic, decide on a title, and add filters. It's that easy, ensuring your content is ready for any platform hassle-free.`,
              img: '/generate.png',
              alt: 'Generate Section'
            },
            {
              title: "Our Words, Your Way",
              description: `Just share your existing content or product details, get extra suggestions, click the button, and your refreshed content is ready to go in a snap. It's a quick and easy way to keep your writing or product pitches fresh and engaging.`,
              img: '/regenerate.png',
              alt: 'Regenerate Section'
            },
            {
              title: "From Draft to Perfection",
              description: `Click save, and it stores both the inputs and outputs. Need to find them later? No problem. Sort by platform, click, and everything is right there for you. It's a quick and simple way to manage and perfect your writing drafts.  `,
              img: '/history.png',
              alt: 'History Section'
            },
          ].map((item, index) => (
            <Featurecard
              key={index}
              index={index + 1}
              title={item.title}
              description={item.description}
              img={item.img}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Features;
