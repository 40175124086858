import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className="p-6 sm:p-16 pt-8 pb-8 flex flex-col gap-6 sm:gap-10 bg-[#6035e0]">
      <Link
        to={"/"}
        className="text-white flex flex-row items-center gap-2 w-fit"
      >
        <MdKeyboardBackspace size={24} />
        <p>Back</p>
      </Link>
      <p className="font-bold text-3xl sm:text-5xl text-white">
        Terms of Service
      </p>
      <div className="text-white flex flex-col gap-3 pb-5">
        <p>
          Welcome to TypeFast AI! Before using our services, please carefully read and understand the following Terms and Conditions.
        </p>
        <p className="font-bold text-xl">1. Acceptance of Terms</p>
        <p>
          By accessing or using TypeFast AI, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.
        </p>
        <p className="font-bold text-xl">2. Use of Services</p>
        <p>
          <span className="font-bold">2.1 </span> You must be at least 18 years old or have the legal capacity to enter into a binding contract to use TypeFast AI.
        </p>
        <p>
          <span className="font-bold">2.2 </span> You agree to use TypeFast AI solely for lawful purposes and in accordance with these Terms and Conditions.
        </p>
        <p className="font-bold text-xl">3. Account Registration</p>
        <p>
          To access certain features of TypeFast AI, you may be required to register an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </p>
        <p className="font-bold text-xl">4. User Content</p>
        <p>
          You retain ownership of the content you generate using TypeFast AI. However, by using our services, you grant TypeFast AI a non-exclusive, royalty-free, worldwide license to use, reproduce, and distribute your content for the purpose of providing and improving our services.
        </p>
        <p className="font-bold text-xl">5. Prohibited Activities</p>
        <p>
          You agree not to engage in any activities that may interfere with or disrupt the operation of TypeFast AI or violate these Terms and Conditions.
        </p>
        <p className="font-bold text-xl">6. Privacy</p>
        <p>
          Your use of TypeFast AI is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
        </p>
        <p className="font-bold text-xl">7. Modifications to Services</p>
        <p>
          TypeFast AI reserves the right to modify or discontinue services temporarily or permanently, with or without notice.
        </p>
        <p className="font-bold text-xl">8. Termination</p>
        <p>
          TypeFast AI may terminate or suspend your access to the services immediately, without prior notice or liability, for any reason, including a breach of these Terms and Conditions.
        </p>
        <p className="font-bold text-xl">9. Limitation of Liability</p>
        <p>
          TypeFast AI and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
        </p>
        <p className="font-bold text-xl">10. Governing Law</p>
        <p>
          These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
        </p>
        <p className="font-bold text-xl">11. Contact Us</p>
        <p>
          If you have any questions or concerns about these Terms and Conditions, please contact us at diyanshu0360@gmail.com.
        </p>
        <p>By using TypeFast AI, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
        </p>
        <p>
          Thank you for using TypeFast AI. We hope you enjoy creating compelling
          product marketing content with our tool!
        </p>
        <p>Last Updated: 11 December, 2023</p>
      </div>
    </div>
  );
}

export default Terms;
