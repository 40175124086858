import { useUser } from "@clerk/clerk-react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Subscribe() {
  const navigate = useNavigate();
  const userInfo = useUser();

  return (
    <div className="w-full bg-rose-500 ">
      <div className="max-w-7xl w-full m-auto px-5 py-20 text-white grid grid-cols-1 sm:grid-cols-2">
        <div className="text-center md:text-start col-span-1 gap-2">
          <p className="font-bold text-3xl md:text-5xl w-full md:w-[90%] leading-[40px] md:leading-[60px]">
            No Cost, No Catch
          </p>
          <p className="w-full m-auto">
            Get Started with TypeFast and Enjoy Free Articles & Access to Upcoming features!
          </p>
        </div>
        <div className="mt-5 md:mt-0 col-span-1 flex flex-col md:flex-row gap-5 justify-center items-center">
          <a
            href="mailto:diyanshu0360@gmail.com"
            className="border-b-2 font-semibold mb-2 md:mb-0 order-2 md:order-1"
          >
            Contact Us
          </a>
          <button
            onClick={
              userInfo.user
                ? () => navigate("/dashboard")
                : () => navigate("/sign-up")
            }
            className="bg-white text-black p-3 ps-5 pe-5 rounded-full shadow-sm hover:scale-105 transition-all duration-100 hover:shadow-md font-semibold order-1 md:order-2"
          >
            Generate Free Articles
          </button>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
